import {
  Box, Flex, Spacer,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Map from './map';
import VesselStatusIcon from './vesselStatusIcon';
import VesselMapOverlay from './vesselMapOverlay';
import { calculateMissionProgress } from '../utils/helpers';

function SingleVesselTile({
  vessel,
} : any) {
  const navigate = useNavigate();
  const [enableCameraView, setEnableCameraView] = useState(true);
  const initialCoordinates = vessel?.missions[0]?.location.coordinates
    || null;



  const toVesselDetail = async (e : any) => {
    e.stopPropagation();
    navigate(`/vessels/${vessel.id}`);
  };
  const missionProgress = calculateMissionProgress(
    vessel.missions[0]?.startedAt,
    vessel.missions[0]?.estimatedLength,
  );

  // TODO: get eta
  const mockEta = 'time or distance?';
  // TODO: get power consumption
  const mockPowerConsumption = 'Current power consumption';
  // TODO: get remaining distance
  const mockRemainingDistance = 'Remaining distance based on fuel';
  // TODO: get ps and sb
  const mockPs = 100;
  const mockSb = 100;

  return (
    <Box
      p="15px"
      mt="15"
      height="80vh"
    >
      <Flex
        onClick={toVesselDetail}
        cursor="pointer"
        title="Open vessel details"
      >
        <Box>
          {vessel.name}
          {': '}
          {vessel.vehicleStatus?.description}
        </Box>
        <Spacer />
        {vessel?.missions[0]?.location
          ? (
            <Box>
              Location:
              {' '}
              {vessel?.missions[0]?.location.name}
            </Box>
          )
          : null}
        <Spacer />
        <Box>
          <VesselStatusIcon
            isError={vessel?.vehicleStatus?.isError}
            dataConnection={vessel?.vehicleStatus?.dataConnection}
          />
        </Box>
      </Flex>
      <Flex>
        <Map
          vesselId={vessel.id}
          style={{ height: '80vh', width: '100%' }}
          pathData={vessel.missions[0].missionPath}
          toggleCamera={() => setEnableCameraView(!enableCameraView)}
          initialCameraView={enableCameraView}
          depthData={vessel.missions[0]?.depth}
          aisData={vessel.missions[0]?.ais}
          depthThreshold={10}
          initialCoordinates={initialCoordinates}
        />
        <VesselMapOverlay
          progressBar={missionProgress}
          propProgressBar={mockPs}
          pssbProgressBar={mockSb}
          sog={vessel.missions[0]?.sog}
          eta={mockEta}
          powerConsumption={mockPowerConsumption}
          remainingDistance={mockRemainingDistance}
          enableCameraView={enableCameraView}
        />
      </Flex>
    </Box>
  );
}

export default SingleVesselTile;
