/* eslint-disable react/function-component-definition */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-new */
import React from 'react';
import {
  Box, Flex, Spacer,
} from '@chakra-ui/react';
import { MdOutlineZoomOutMap } from 'react-icons/md';
import { Chart, TimeScale, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-moment';

Chart.register(...registerables, zoomPlugin, TimeScale);

export default class Graph extends React.Component<any, any> {
  constructor({props} : any) {
    super(props);

    this.state = {
      chartRef: React.createRef(),
      chart: null,
    };
  }

  componentDidMount() {
    const ctx = this.state.chartRef.current.getContext('2d');
    const theData = this.props.measurementData;
    // Chart.register(zoomPlugin);

    const myChart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            label: `${this.props.heading} (${this.props.sensorUnit})`,
            data: theData,
            fill: false,
            indexAxis: 'x',
            backgroundColor: '#00bfff',
            borderColor: '#00bfff',
          },
        ],
      },
      options: {
        animation: false,
        parsing: false,
        plugins: {
          zoom: {
            zoom: {
              drag: {
                enabled: true,
              },
              mode: 'x',
            },

          },
          decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 200,

          },
          tooltip: {
            intersect: false,
          },
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: this.props.selectedTimeRange.value === 168 ? 'hour' : 'minute',
              displayFormats: {
                millisecond: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                second: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                minute: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                hour: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                day: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                week: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                month: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                quarter: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
                year: this.props.selectedTimeRange.value === 168 ? 'DD-MM HH:mm' : 'HH:mm',
              },
              tooltipFormat: 'DD.MM.YYYY HH:mm:ss',
            },
            ticks: {
              source: 'auto',
              // Disabled rotation for performance
              maxRotation: 0,
              autoSkip: true,
            },
          },
          y: {},

        },
      },

    });
    this.setState({
      chart: myChart,
    });
  }

  handleResetZoom() {
    this.state.chart.resetZoom();
  }

  render() {
    return (
      <Box mb={10} pl={2} pr={2}>
        <Flex>
          <Spacer />
          <MdOutlineZoomOutMap onClick={() => this.handleResetZoom()} />
        </Flex>
        <canvas
          id="myChart"
          ref={this.state.chartRef}
        />
      </Box>
    );
  }
}
