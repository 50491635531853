import { IVehicleSubsystem } from '../interfaces/vehicleSubsystem';
import { IUser } from '../interfaces/user';
import { IMission } from '../interfaces/mission';
import { IVessel } from '../interfaces/vessel';
import { ILocation } from '../interfaces/location';

export const initialSubsystem = (): IVehicleSubsystem => ({
  id: 0,
  vehicleId: 0,
  subsystemTypeId: 0,
  parameter: '',
  subsystemType: {
    description: '',
    vehicleKey: '',
  },
});

export const initialUser = (): IUser => ({
  id: 0,
  email: '',
  userRoleId: 0,
  role: 'view-only',
  firstName: '',
  surname: '',
  password: '',
  isActive: true,
});

export const initialMission = (): IMission => ({
  id: 0,
  name: '',
  scheduledAt: '',
  scheduledUntil: undefined,
  startedAt: '',
  endedAt: '',
  missionPath: [],
  active: true,
  recurrencyPattern: '',
  rrule: '',
  estimatedLength: 0,
  vehicleId: 0,
  locationId: 0,
  adhoc: false,
  parentMissionId: 0,
  depth: [],
  ais: [],
  sog: 0,
});

export const initialVessel = (): IVessel => ({
  id: 0,
  name: '',
  maxSpeed: 0,
  draft: 0,
  batteryCapacity: 0,
  fuelTankSize: 0,
  status: true,
  accessToken: '',
  vehicleSubsystems: [],
});

export const initialLocation = (): ILocation => ({
  id: 0,
  name: '',
  coordinates: undefined,
});
