import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  Box, Heading, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Text,
  Spacer, Flex, CircularProgress, useToast, SimpleGrid,
} from '@chakra-ui/react';
import {
  forwardUnauthenticatedUser, deleteRequestHandle, getRequestHandle,
} from '../../../utils/helpers';
import Wrapper from '../../../components/wrapper';
import DeleteButton from '../../../components/deleteButton';
import EditButton from '../../../components/editButton';
import LocationService from '../../../services/location.service';
import { ILocation } from '../../../interfaces/location';
import {
  deleteSuccessToast, generalErrorToast, deleteFailToast,
} from '../../../utils/sharedObjects';
import CreateButton from '../../../components/createButton';
import { IRootState } from '../../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../../components/pageWrapper';

const ListLocations = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const currentUser = useSelector((state: IRootState) => state.user);

  const [locations, setLocations] = useState<Array<ILocation>>([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    forwardUnauthenticatedUser(currentUser);

    const getLocations = async () => {
      const response = await LocationService.getAll();
      const getResponse = getRequestHandle(response);

      if (getResponse && response.data) {
        /* eslint-disable no-param-reassign */
        response.data.forEach((location : any) => {
          location.coordinates = [
            location.coordinates?.coordinates[0],
            location.coordinates?.coordinates[1],
          ];
        });
        /* eslint-enable no-param-reassign */
        setLocations(response.data);
        setIsLoading(false);
      }
    };

    getLocations().catch((e) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });
  }, [currentUser, toast]);

  const deleteLocation = async (locationId: number) => {
    setIsDeleteLoading(true);
    const response = await LocationService.deleteOneById(locationId);
    const deleteResponse = deleteRequestHandle(response);
    if (deleteResponse) {
      toast(deleteSuccessToast('Location'));
      setLocations(locations.filter((location) => location.id !== locationId));
    } else {
      toast(deleteFailToast('Location'));
    }
    setIsDeleteLoading(false);
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Locations</Heading>
          </Box>
          <Spacer />
          <Box>
            <CreateButton
              title="Add location"
              route="/admin/locations/add"
            />
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="large">
        <Table variant="simple">
          <TableCaption><Text as="i">All locations</Text></TableCaption>
          <Thead>
            <Tr>
              <Th isNumeric>ID</Th>
              <Th>Name</Th>
              <Th>Longitude</Th>
              <Th>Latitude</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? (
                <Tr>
                  <Td colSpan={2} />
                  <Td colSpan={3}>
                    <CircularProgress isIndeterminate color="teal.500" />
                  </Td>
                </Tr>
              )
              : null}
            {
            locations.map((location: ILocation) => (
              <Tr key={location.id}>
                <Td isNumeric>{location.id}</Td>
                <Td>{location.name}</Td>
                <Td>{location.coordinates ? location.coordinates[0] : null}</Td>
                <Td>{location.coordinates ? location.coordinates[1] : null}</Td>
                <Td>
                  <SimpleGrid columns={2} width={100}>
                    <Box>
                      <DeleteButton
                        title="delete location"
                        isDisabled={isDeleteLoading}
                        onClick={() => deleteLocation(location.id)}
                      />
                    </Box>
                    <Box>
                      <EditButton
                        title="edit location"
                        onClick={() => navigate(`/admin/locations/${location.id}`)}
                      />
                    </Box>
                  </SimpleGrid>
                </Td>
              </Tr>
            ))
            }
          </Tbody>
        </Table>
      </Wrapper>
    </PageWrapper>
  );
}

export default ListLocations;
