import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  Box,
  Heading,
  Button,
  Icon,
  Spacer,
  Flex,
  useToast,
  SimpleGrid,
  Spinner,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { MdOutlineFormatListBulleted } from 'react-icons/md';
import { t } from 'i18next';
import {
  calculateMissionProgress,
  createBatteryCurrentMeasurementPoints,
  createBatteryVoltageMeasurementPoints,
  createWindSpeedMeasurementPoints,
  createMotorPowerConsumptionMeasurementPoints,
  createSpeedMeasurementPoints,
  forwardUnauthenticatedUser,
  getRequestHandle,
} from '../../utils/helpers';
import Wrapper from '../../components/wrapper';
import Map from '../../components/map';
import MissionService from '../../services/mission.service';
import { generalErrorToast } from '../../utils/sharedObjects';
import Graph from '../../components/graph';
import { initialVessel } from '../../utils/initialValues';
import VesselMapOverlay from '../../components/vesselMapOverlay';
import VesselStatusIcon from '../../components/vesselStatusIcon';
import VesselService from '../../services/vessel.service';
import { IRootState } from '../../interfaces/rootState';
import { IDepth } from '../../interfaces/depth';
import PageWrapper from '../../components/pageWrapper';

const DetailsMission = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);
  const { missionId } = useParams();
  const [measurements, setMeasurements] = useState({} as any);
  const [mission, setMission] = useState({} as any);
  const [vessel, setVessel] = useState(initialVessel);
  const [pathDepth, setPathDepth] = useState([]);
  const [plannedPath, setPlannedPath] = useState([]);
  const [initialCoordinates, setInitialCoordinates] = useState([]);
  const [actualPath, setActualPath] = useState<any[]>([]);
  const [depthPoints, setDepthPoints] = useState<any[]>([]);
  const [reloadVessel, setReloadVessel] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [enableCameraView, setEnableCameraView] = useState(true);
  const [mapHeight, setMapHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isGraphLoading, setGraphLoading] = useState(true);
  const [missionCameraCoordinates, setMissionCameraCoordinates] = useState<any[]>([]);
  const [oldMissionId, setOldMissionId] = useState<any>();

  type ActualPathType = {
    id: number,
    c: Array<number>,
    v: number
  }

  useEffect(() => {
    forwardUnauthenticatedUser(user);

    const getMission = async () => {
      if (Number.isNaN(Number(missionId))) {
        navigate('/404');
      }
      if (missionId !== undefined){
      const response = await MissionService.getAllMeasurements(+missionId);
      if (getRequestHandle(response)) {
        setMeasurements({
          batteryCurrent: createBatteryCurrentMeasurementPoints(response),
          batteryVoltage: createBatteryVoltageMeasurementPoints(response),
          windSpeed: createWindSpeedMeasurementPoints(response),
          speed: createSpeedMeasurementPoints(response),
          motorPower: createMotorPowerConsumptionMeasurementPoints(response),
        });
        const progress = calculateMissionProgress(
          response.data?.mission?.startedAt,
          response.data?.mission?.estimatedLength,
        );
        setMission({ ...response.data?.mission, progress });
      } else {
        navigate('/404');
      }
    }
    };

    getMission().catch((e: Error) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });
    setGraphLoading(false);
  }, [navigate, user, toast, missionId]);


  useEffect(() => {
    forwardUnauthenticatedUser(user);
    
    const getVessel = async () => {
      if (Number.isNaN(Number(missionId))) {
        navigate('/404');
      } else if (reloadVessel && missionId !== undefined) {
        setRefreshData(false);
        const missionResponse = await MissionService.getOneById(+missionId);
        const vesselId = missionResponse?.data?.vehicleId;
        const response = await VesselService.getVesselWithMission(vesselId, Number(missionId));
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setVessel(response.data.vehicle);
          if (response.data?.vehicle?.missions?.length > 0) {
            const myPlannedMissionPath = JSON.parse(
              response?.data?.vehicle?.missions[0]?.missionPath,
            );
            setPlannedPath(myPlannedMissionPath);
            setInitialCoordinates(
              response.data?.vehicle?.missions[0]?.location?.coordinates?.coordinates,
            );
            setReloadVessel(false);
            setIsLoading(false);
            const pathDepthResponse = await MissionService.getPathDepths(+missionId);
            const myActualPath : any[] = [];
            const myDepthPoints : IDepth[] = [];
            let coords : Array<number> = [];
            pathDepthResponse.data.forEach((element : ActualPathType) => {
              if (element.c !== null) {
                if (coords.length < 1) {
                  coords.push(element.c[0], element.c[1]);
                }
                myActualPath.push(
                  [element.c[0], element.c[1]]
                  );
                myDepthPoints.push(
                  { 
                    id: element.id,
                    coordinates: [element.c[0], element.c[1]], 
                    value: element.v,
                  }
                );
              }
            });
            setMissionCameraCoordinates(coords);
            getRequestHandle(pathDepthResponse);
            setPathDepth(pathDepthResponse.data);
            setActualPath(myActualPath);
            setDepthPoints(myDepthPoints);
          }
        } else {
          navigate('/404');
        }
      }
    };

    if (oldMissionId !== missionId) {
      setReloadVessel(true);
      getVessel().catch((e: Error) => {
        if (!e.message.includes('404') && !e.message.includes('401')) {
          toast(generalErrorToast());
          console.error(e.message);
        }
      });
    }

    return () => {
      setOldMissionId(missionId);
    };
  }, [navigate, missionId, user, toast, reloadVessel, refreshData, oldMissionId]);

  let dashboardBody;

  if (vessel?.id === 0) {
    dashboardBody = isLoading
      ? (
        <Wrapper variant="small">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Wrapper>
      )
      : (
        <Wrapper variant="small">
          <Box>
            {t('vessel.No vessels available')}
          </Box>
        </Wrapper>
      );
  } else {
    let missionProgress = 0;
    if (vessel !== undefined && vessel.missions !== undefined && vessel?.missions[0]?.startedAt !== undefined && vessel?.missions[0]?.estimatedLength !== undefined) {
    missionProgress = calculateMissionProgress(
      vessel?.missions[0]?.startedAt,
      vessel?.missions[0]?.estimatedLength,
    );
    }

    // TODO: get eta
    const mockEta = 'time or distance?';
    // TODO: get power consumption
    const mockPowerConsumption = 'Current power consumption';
    // TODO: get remaining distance
    const mockRemainingDistance = 'Remaining distance based on fuel';
    // TODO: get ps and sb
    const mockPs = 100;
    const mockSb = 100;
    dashboardBody = (
      <Box>
        {pathDepth.length < 1 && !isLoading && (
          <Text as="b" color="red" w="100%" display="block" textAlign="center">
            No depth data found
          </Text>
        )}
        <Flex>
          <Spacer />
          <Button
            onClick={() => { setRefreshData(true); }}
            isDisabled={!reloadVessel}
          >
            Refresh
          </Button>
        </Flex>
        <Flex>
          <Map
            vesselId={vessel?.id}
            style={{ height: '80vh', width: '100%' }}
            pathData={plannedPath}
            actualPathData={actualPath}
            toggleCamera={() => setEnableCameraView(!enableCameraView)}
            initialCameraView={enableCameraView}
            depthData={depthPoints}
            depthThreshold={10}
            aisData={vessel !== undefined && vessel.missions !== undefined ? vessel?.missions[0]?.ais : undefined}
            initialCoordinates={
              pathDepth?.length > 0
                ? missionCameraCoordinates
                : initialCoordinates
            }
          />
          <VesselMapOverlay
            progressBar={missionProgress}
            propProgressBar={mockPs}
            pssbProgressBar={mockSb}
            sog={vessel !== undefined && vessel.missions !== undefined ? vessel?.missions[0]?.sog : undefined}
            eta={mockEta}
            powerConsumption={mockPowerConsumption}
            remainingDistance={mockRemainingDistance}
            enableCameraView={enableCameraView}
          />
        </Flex>
      </Box>
    );
  }

  const graphsbody = (
    <>
      {/* {mission?.progress && false && (
        <Wrapper variant="large">
          <Heading size="sm" mt={100}>
            Mission Progress:
            {` ${mission?.progress}%`}
          </Heading>
          <Box w="700px" title={mission?.progress?.toString()}>
            <Progress
              colorScheme="green"
              size="sm"
              value={mission?.progress}
              border="1px"
            />
          </Box>
        </Wrapper>
      )} */}
      <Wrapper variant="large">
        {isGraphLoading ? (
          <Wrapper variant="small">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Wrapper>
        ) : (
          <Wrapper variant="large">
            {Object.keys(measurements).length !== 0 ? (
              <SimpleGrid columns={2} className="no-select">
                {Object.keys(measurements.windSpeed).length !== 0
                  ? (
                    <Graph
                      measurementData={measurements.windSpeed}
                      selectedTimeRange={24}
                      dataKey="windSpeed"
                      sensorUnit="m/s"
                      heading="Wind speed"
                    />
                  ) : <div /> }
                <Graph
                  measurementData={measurements.speed}
                  selectedTimeRange={24}
                  dataKey="speed"
                  sensorUnit="m/s"
                  heading="Vessel speed"
                />
                <Graph
                  measurementData={measurements.batteryCurrent}
                  selectedTimeRange={24}
                  dataKey="i1"
                  sensorUnit="A"
                  heading="Main battery current"
                />
                <Graph
                  measurementData={measurements.batteryVoltage}
                  selectedTimeRange={24}
                  dataKey="v1"
                  sensorUnit="V"
                  heading="Main battery voltage"
                />
                <Graph
                  measurementData={measurements.motorPower}
                  selectedTimeRange={24}
                  dataKey="w1"
                  sensorUnit="W"
                  heading="Motor Power Consumption"
                />
              </SimpleGrid>
            ) : (
              <Box mt={25}>
                <Text style={{ fontSize: '25px', textAlign: 'center' }}>
                  No mission measurement data found
                </Text>
              </Box>
            )}
          </Wrapper>
        )}
      </Wrapper>
    </>
  );

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="40px" pt="15px">
            <Heading size="md">
              Mission:
              {' '}
              {mission.name}
            </Heading>
          </Box>
          <Spacer />
          <Box pt="7px">
            <Button
              type="submit"
              colorScheme="teal"
              onClick={() => navigate('/missions')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              Mission List
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      <Tabs isFitted variant="enclosed" width="75%" margin="0 auto" mt={50}>
        <TabList mb="1em">
          <Tab>Map</Tab>
          <Tab>Charts</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Wrapper variant="large">
              {dashboardBody}
            </Wrapper>
          </TabPanel>
          <TabPanel>
            {graphsbody}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
}

export default DetailsMission;
