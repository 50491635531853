import { UseToastOptions } from '@chakra-ui/react';

const baseToastConfig = {
  status: 'success',
  position: 'top',
  isClosable: true,
  duration: 2000,
} as UseToastOptions;

const errorToastConfig = {
  status: 'error',
  position: 'top',
  isClosable: true,
  duration: 2000,
} as UseToastOptions;

export const deleteSuccessToast = (entity?: string) => ({
  title: `${entity || 'User'} deleted.`,
  ...baseToastConfig,
}) as UseToastOptions;

export const createSuccessToast = (entity?: string) => ({
  title: `${entity || 'User'} created.`,
  ...baseToastConfig,
}) as UseToastOptions;

export const updateSuccessToast = (entity?: string) => ({
  title: `${entity || 'User'} updated.`,
  ...baseToastConfig,
}) as UseToastOptions;

export const updateFailToast = (entity?: string, title?: string) => {
  if (title) {
    return {
      title,
      ...errorToastConfig,
    } as UseToastOptions;
  }
  return {
    title: `${entity || 'User'} could not be updated.`,
    ...errorToastConfig,
  } as UseToastOptions;
};

export const createFailToast = (entity?: string, title?: string) => {
  if (title) {
    return {
      title,
      ...errorToastConfig,
    } as UseToastOptions;
  }
  return {
    title: `${entity || 'User'} could not be created.`,
    ...errorToastConfig,
  } as UseToastOptions;
};

export const deleteFailToast = (entity?: string, title?: string) => {
  if (title) {
    return {
      title,
      ...errorToastConfig,
    } as UseToastOptions;
  }
  return {
    title: `${entity || 'User'} could not be created.`,
    ...errorToastConfig,
  } as UseToastOptions;
};

export const generalErrorToast = () => ({
  title: 'An error occurred!',
  ...errorToastConfig,
}) as UseToastOptions;
