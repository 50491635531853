import React from 'react';
import {
  Button,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const CancelButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Button
      marginTop="15px"
      marginLeft="10px"
      type="button"
      colorScheme="red"
      onClick={() => navigate(-1)}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
