import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { MdOutlineFormatListBulleted, MdSave } from 'react-icons/md';
import {
  Box, Button, Flex, FormLabel, Heading, Icon, Input, Spacer, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import HarborLocationMap from '../../../components/harborMap';
import CancelButton from '../../../components/cancelButton';
import InputField from '../../../components/inputField';
import Wrapper from '../../../components/wrapper';
import {
  createRequestHandle,
} from '../../../utils/helpers';
import {
  createFailToast,
  createSuccessToast,
} from '../../../utils/sharedObjects';
import { initialLocation } from '../../../utils/initialValues';
import { ILocation } from '../../../interfaces/location';
import LocationService from '../../../services/location.service';
import PageWrapper from '../../../components/pageWrapper';

const AddLocation = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [location, setLocation] = useState(initialLocation);
  const [coordinates, setCoordinates] = useState<Array<number>>([]);

  const save = async (changedLocation: { location: ILocation }) => {
    const newData = changedLocation.location;
    const response = await LocationService.addOne(newData);
    const createResponse = createRequestHandle(response);
    if (createResponse) {
      toast(createSuccessToast('Location'));
      navigate('/locations');
    } else {
      toast(createFailToast('Location'));
    }
  };

  const showOnMap = () => {
    if (typeof document !== 'undefined') {
      const latValue = Number((document.getElementsByName('lat')[0] as HTMLInputElement).value);
      const lngValue = Number((document.getElementsByName('lng')[0] as HTMLInputElement).value);
      location.coordinates = [
          latValue,
          lngValue,
        ];
      setLocation(location);
      setCoordinates([
        latValue,
        lngValue,
      ]);
    }
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Add Location</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              type="submit"
              colorScheme="teal"
              onClick={() => navigate('/admin/locations')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              List locations
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="medium">
        <Formik
          initialValues={{ location }}
          onSubmit={save}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField
                name="location.id"
                required
                type="hidden"
              />
              <InputField
                label="Name"
                name="location.name"
                required
                type="text"
              />
              <Box marginTop="15px">
                <FormLabel>
                  Latitude
                </FormLabel>
                <Input
                  placeholder="lat"
                  name="lat"
                  required
                  defaultValue={coordinates[0]}
                />
              </Box>
              <Box marginTop="15px">
                <FormLabel>
                  Longitude
                </FormLabel>
                <Input
                  placeholder="lng"
                  name="lng"
                  required
                  defaultValue={coordinates[1]}
                />
                <Button
                  marginTop="15px"
                  onClick={showOnMap}
                >
                  Show on map
                </Button>
              </Box>
              {location && (
                <Box marginTop="15px">
                  <HarborLocationMap
                    locationCoordinates={coordinates}
                    newCoordinates={setCoordinates}
                  />
                </Box>
              )}
              <Button
                marginTop="15px"
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
              >
                <Icon as={MdSave} mb="-2px" mr="5px" />
                Save
              </Button>
              <CancelButton />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PageWrapper>
  );
}

export default AddLocation;
