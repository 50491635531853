import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import {
  MapContainer, TileLayer, Marker,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, LatLngExpression } from 'leaflet';
import ChangeMapView from './changeMapView';
import SetMarkerMap from './setMarkerMap';

const initialStyle = { height: '50vh', width: '100vh' };

export default function HarborLocationMap({
  locationCoordinates, newCoordinates, style = initialStyle,
}: any) {
  const [mapFocus, setMapFocus] = useState([0, 0] as LatLngExpression);

  useEffect(() => {
    if (locationCoordinates && locationCoordinates.length > 0) {
      setMapFocus(locationCoordinates);
    }
  }, [locationCoordinates]);

  const markerIcon = new Icon({
    iconUrl: '/marker-small.png',
    shadowUrl: '/marker-shadow.png',
    iconSize: [24, 36],
    iconAnchor: [12, 36],
  });

  return (
    <div>
      <MapContainer
        center={mapFocus}
        zoom={15}
        scrollWheelZoom
        style={style}
      >
        {mapFocus && (
          <>
            <ChangeMapView coords={mapFocus} />
            <SetMarkerMap setMapFocus={setMapFocus} newCoordinates={newCoordinates} />
          </>
        )}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <TileLayer
          url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
          attribution='Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
        />
        {mapFocus && (
          <Marker
            position={mapFocus}
            icon={markerIcon}
          />
        )}
      </MapContainer>
      {mapFocus && (
        <Box w="100vh">
          Current coordinates:
         {mapFocus.toString()}
        </Box>
      )}
    </div>
  );
}
