import { AxiosResponse } from 'axios';
import CrudService from './crud.service';
import api from '../utils/api';

class UserService extends CrudService {
  constructor() {
    super('users');
  }

  getAllUserRoles() {
    return api.get(`/${this.base}/roles`);
  }

  getUserVessels(userId: number): Promise<AxiosResponse> {
    return api.get(`/${this.base}/${userId}/vessels`);
  }

  async updateUserVessels(
    userId: number,
    assignedVessels: { vesselId: number }[],
  ): Promise<AxiosResponse> {
    return api.post(`/${this.base}/${userId}/vessels`, assignedVessels);
  }

  updatePassword(id: number, data: {
    currentPassword: string, newPassword: string, repeatPassword: string
  }) {
    return api.put(`/${this.base}/${id}/password`, data);
  }
}

export default new UserService();
