import CrudService from './crud.service';
import api from '../utils/api';

class MissionService extends CrudService {
  constructor() {
    super('missions');
  }

  getAllMeasurements(missionId: number) {
    return api.get(`/${this.base}/${missionId}/measurements/`);
  }

  getPathDepths(missionId: number) {
    return api.get(`/${this.base}/${missionId}/pathDepth/`);
  }
}

export default new MissionService();
