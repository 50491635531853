import { useState } from 'react';
import {
  Box, Button, Flex, Heading, Icon, Spacer, useToast,
} from '@chakra-ui/react';
import { MdPassword, MdSave } from 'react-icons/md';
import { Form, Formik } from 'formik';
import { useSelector } from "react-redux";
import CancelButton from '../../components/cancelButton';
import Wrapper from '../../components/wrapper';
import { updateRequestHandle } from '../../utils/helpers';
import InputField from '../../components/inputField';
import UserService from '../../services/user.service';
import { generalErrorToast, updateFailToast, updateSuccessToast } from '../../utils/sharedObjects';
import { IRootState } from '../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../components/pageWrapper';

const UserSettings = () => {
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const updatePassword = async (formData : any, resetForm : any) => {
    const response = await UserService.updatePassword(user.id, formData);
    const updateRequest = updateRequestHandle(response);
    if (updateRequest) {
      toast(updateSuccessToast('Password'));
      // empty the form
      setCurrentPassword('');
      setNewPassword('');
      setRepeatPassword('');
      resetForm();
    }
  };

  const save = async (formData: {
    currentPassword: string, newPassword: string, repeatPassword: string
  }, { setErrors, resetForm } : any) => {
    if (formData.currentPassword === formData.newPassword) {
      setErrors({
        newPassword: 'Old and new passwords cannot be the same.',
      });
    } else if (formData.newPassword !== formData.repeatPassword) {
      setErrors({
        repeatPassword: 'Passwords do not match.',
      });
    } else {
      updatePassword(formData, resetForm).catch((e: Error) => {
        if (e.message.includes('404')) {
          toast(updateFailToast('Password'));
          setCurrentPassword('');
          setNewPassword('');
          setRepeatPassword('');
          resetForm();
        } else {
          toast(generalErrorToast());
        }
      });
    }
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">User Settings</Heading>
          </Box>
        </Flex>
        <Spacer />
      </Wrapper>
      <Wrapper variant="small">
        <Formik
          initialValues={{ currentPassword, newPassword, repeatPassword }}
          onSubmit={save}
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField
                label="Current Password"
                name="currentPassword"
                type="password"
                required
                autoComplete="off"
                placeholder="Please enter your current password"
                icon={<Icon as={MdPassword} mb="-2px" mr="5px" />}
              />
              <Box mt="30px">
                <InputField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  required
                  autoComplete="off"
                  placeholder="Please enter new password"
                  icon={<Icon as={MdPassword} mb="-2px" mr="5px" />}
                />
              </Box>
              <Box mt="15px">
                <InputField
                  label="Repeat New Password"
                  name="repeatPassword"
                  type="password"
                  required
                  autoComplete="off"
                  placeholder="Please repeat new password"
                  icon={<Icon as={MdPassword} mb="-2px" mr="5px" />}
                />
              </Box>
              <Button
                marginTop="15px"
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
              >
                <Icon as={MdSave} mb="-2px" mr="5px" />
                Save Password
              </Button>
              <CancelButton />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PageWrapper>
  );
}

export default UserSettings;
