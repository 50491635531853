import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { MdOutlineFormatListBulleted, MdSave } from 'react-icons/md';
import {
  Box, Button, Flex, Heading, Icon, SimpleGrid, Spacer, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import InputField from '../../../components/inputField';
import Wrapper from '../../../components/wrapper';
import {
  forwardUnauthenticatedUser, createRequestHandle,
} from '../../../utils/helpers';
import { createSuccessToast, createFailToast } from '../../../utils/sharedObjects';
import { initialVessel } from '../../../utils/initialValues';
import VesselService from '../../../services/vessel.service';
import { IVessel } from '../../../interfaces/vessel';
import { IRootState } from '../../../interfaces/rootState';
import PageWrapper from '../../../components/pageWrapper';

const AddVessel = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);

  useEffect(() => {
    forwardUnauthenticatedUser(user);
  }, [user, toast]);

  const vesselInit = initialVessel();

  const save = async (formData: { vessel: IVessel }) => {
    const newData = formData.vessel;
    const response = await VesselService.addOne(newData);
    const createResponse = createRequestHandle(response);
    if (createResponse) {
      toast(createSuccessToast('Vessel'));
      navigate(`/vessels/${response.data.id}`);
    } else {
      toast(createFailToast('Vessel'));
    }
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Add Vessel</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              type="submit"
              colorScheme="teal"
              onClick={() => navigate('/admin/vessels')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              List Vessels
            </Button>
          </Box>
        </Flex>
      </Wrapper>

      <Wrapper variant="large">
        <Box pl="15px">
          <Box flex="1" textAlign="left">
            <Heading size="md">General Data</Heading>
          </Box>
          <Formik
            initialValues={{ vessel: vesselInit }}
            onSubmit={save}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <Form>
                <SimpleGrid columns={2} spacing={5}>
                  <Box>
                    <InputField
                      name="vessel.id"
                      required
                      type="hidden"
                    />
                    <Box marginTop="15px">
                      <InputField
                        label="Name"
                        name="vessel.name"
                        type="text"
                      />
                    </Box>
                    <Box marginTop="15px">
                      <InputField
                        label="Max Speed"
                        name="vessel.maxSpeed"
                        type="text"
                      />
                    </Box>
                    <Box marginTop="15px">
                      <InputField
                        label="Draft"
                        name="vessel.draft"
                        type="text"
                      />
                    </Box>
                    <Box marginTop="15px">
                      <InputField
                        label="Battery Capacity"
                        name="vessel.batteryCapacity"
                        type="text"
                      />
                    </Box>
                    <Box marginTop="15px">
                      <InputField
                        label="Fuel Tank Size"
                        name="vessel.fuelTankSize"
                        type="text"
                      />
                    </Box>
                  </Box>
                </SimpleGrid>
                <Button
                  marginTop="15px"
                  type="submit"
                  colorScheme="teal"
                  isLoading={isSubmitting}
                >
                  <Icon as={MdSave} mb="-2px" mr="5px" />
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Wrapper>
    </PageWrapper>
  );
}

export default AddVessel;
