/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useRef, useState } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  CircleMarker,
  Tooltip,
} from 'react-leaflet';
import {Icon, latLng, LatLngExpression } from 'leaflet';
import {
  Box, Checkbox, CheckboxGroup, Input, Stack, Text,
} from '@chakra-ui/react';
import ChangeMapView from './changeMapView';
import 'leaflet/dist/leaflet.css';
import { IDepth } from '../interfaces/depth';
import { IAis } from '../interfaces/ais';

type MapProps = {
  vesselId?: number,
  style?: Object,
  onMapCreated?: () => void,
  enableLayers?: boolean,
  depthData?: IDepth[],
  pathData?: LatLngExpression[],
  actualPathData?: LatLngExpression[],
  aisData?: IAis[],
  initialCameraView: boolean,
  depthThreshold: number,
  zoom?: number,
  toggleCamera?: (t: boolean) => void,
  initialCoordinates?: number[],
};

const MapDefaultProps = {
  vesselId: undefined,
  style: { height: '50vh', width: '100%' },
  onMapCreated: () => {},
  enableLayers: true,
  depthData: [],
  pathData: [],
  actualPathData: [],
  aisData: [],
  initialCameraView: true,
  depthThreshold: 0,
  toggleCamera: () => {},
  zoom: 15,
};

const Map: React.FC<MapProps> = ({
  onMapCreated,
  vesselId,
  style,
  enableLayers,
  initialCameraView,
  toggleCamera,
  depthData,
  pathData,
  actualPathData,
  aisData,
  depthThreshold,
  initialCoordinates,
  zoom,
}) => {
  const boatIcon = new Icon({
    iconUrl: '/boat.png',
    iconSize: [25, 25],
  });
  // TODO: add iconRetinaUrl: '/marker-big.png' if retina screens are used in the future
  // const markerIcon = new Icon({
  //   iconUrl: '/marker-small.png',
  //   shadowUrl: '/marker-shadow.png',
  // });
  const [, setMapFocus] = useState(
    initialCoordinates || [59.44241199628273, 24.75928028707022],
  );

  const [asvLocation, setAsvLocation] = useState(null);
  const [, setDepthMarker] = useState(null as unknown as IDepth);
  const [aisMarker, setAisMarker] = useState(null as unknown as IAis);
  const [path, setPath] = useState<LatLngExpression[] | undefined>(undefined);
  const [actualPath, setActualPath] = useState<LatLngExpression[] | undefined>(undefined);
  const [depthThresholdValue, setDepthThresoldValue] = useState(depthThreshold);
  const [depthMarkers, setDepthMarkers] = useState<any | null>(null);
  // layers
  const [depthLayer, toggleDepthLayer] = useState(true);
  const [pathLayer, togglePathLayer] = useState(true);
  const [actualPathLayer, toggleActualPathLayer] = useState(true);
  const [aisLayer, toggleAisLayer] = useState(true);
  let ws = useRef(null);

  // useEffect(() => {
  //   // TODO: Change to NYMO websocket
  //   let wsCurrent = ws.current;
  //   wsCurrent = new WebSocket(`ws://localhost:8000?id=${vesselId}`);
  //   wsCurrent.onmessage = (message) => {
  //     const data = JSON.parse(message.data);
  //     if (data && data.coordinates) {
  //       setAsvLocation([data.coordinates[0], data.coordinates[1]]);
  //       setMapFocus([data.coordinates[0], data.coordinates[1]]);
  //     }
  //   };

  //   return () => {
  //     wsCurrent.close();
  //   };
  // }, [vesselId]);

  useEffect(() => {
    if (pathData && !Array.isArray(pathData)) {
      setPath(JSON.parse(pathData));
    } else {
      setPath(pathData);
    }
  }, [pathData]);

  useEffect(() => {
    if (actualPathData && !Array.isArray(actualPathData)) {
      setActualPath(JSON.parse(actualPathData));
    } else {
      setActualPath(actualPathData);
    }
  }, [actualPathData]);

  useEffect(() => {
    const value = depthThresholdValue;
    setDepthMarkers(null)
    const myDepthMarkers: any = [];
    if (depthData !== undefined){
    depthData.forEach((element : IDepth) => {
      if (element.value < value * 1000 && element.coordinates !== undefined) {
        myDepthMarkers.push(
          <CircleMarker
            key={element.id}
            center={[element.coordinates[0], element.coordinates[1]]}
            color="#FF0000"
            fillColor="#FF0000"
            fillOpacity={1}
            radius={3}
          >
            <Tooltip>
              {element.value / 1000}
              {' '}
              m
            </Tooltip>
          </CircleMarker>
        );
      } else if ((element.value < (value * 1000 + 1000)) && (element.value > value * 1000) && element.coordinates !== undefined) {
        myDepthMarkers.push(
          <CircleMarker
            key={element.id}
            center={[element.coordinates[0], element.coordinates[1]]}
            color="#FFFF00"
            fillColor="#FFFF00"
            fillOpacity={1}
            radius={3}
          >
            <Tooltip>
              {element.value / 1000}
              {' '}
              m
            </Tooltip>
          </CircleMarker>
        );
      }
    });
  }
    setDepthMarkers(myDepthMarkers);
  }, [depthThresholdValue, depthData]);

  const handleDepthUpdate = (event: any ) => {
    const { value } = event.target;
    if (value !== depthThresholdValue) {
      setDepthThresoldValue(value);
    }
  };

  const noCamera = () => {}

  return (
    <MapContainer
      zoom={zoom}
      scrollWheelZoom
      style={style}
      
      whenReady={onMapCreated}
      center={initialCoordinates ? latLng(initialCoordinates[0], initialCoordinates[1]) : undefined}
    >
      <ChangeMapView coords={initialCoordinates} />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <TileLayer
        url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
      />
      {enableLayers
        ? (
          <Box position="absolute" zIndex={500} right={0} backgroundColor="white" p="10px">
            <CheckboxGroup colorScheme="teal">
              <Stack spacing="2px" direction={['row', 'column']}>
                <Checkbox
                  isChecked={pathLayer}
                  onChange={() => {
                    togglePathLayer(!pathLayer);
                  }}
                >
                  <Text fontSize="14">Planned Path</Text>
                </Checkbox>
                <Checkbox
                  isChecked={actualPathLayer}
                  onChange={() => {
                    toggleActualPathLayer(!actualPathLayer);
                  }}
                >
                  <Text fontSize="14">Actual Path</Text>
                </Checkbox>
                <Checkbox
                  isChecked={aisLayer}
                  onChange={() => {
                    toggleAisLayer(!aisLayer);
                  }}
                >
                  <Text fontSize="14">AIS data</Text>
                </Checkbox>
                <Checkbox
                  isChecked={initialCameraView}
                  onChange={() => {
                    toggleCamera !== undefined ? toggleCamera(!initialCameraView) : noCamera();
                  }}
                >
                  <Text fontSize="14">Camera</Text>
                </Checkbox>
                <Checkbox
                  isChecked={depthLayer}
                  onChange={() => {
                    toggleDepthLayer(!depthLayer);
                  }}
                >
                  <Text fontSize="14">Depth</Text>
                </Checkbox>
                <Stack spacing="5px" direction={['row']}>
                  <Text pt="5px" fontSize="14">Depth threshold (m):</Text>
                  <Input
                    value={depthThresholdValue}
                    onChange={handleDepthUpdate}
                    size="sm"
                    width="50px"
                  />
                </Stack>
              </Stack>
            </CheckboxGroup>
          </Box>
        ) : null}
      {/* add markers for depth */}
      {/* {depthData && depthLayer ? (
        depthData.map(
          (depth) => {
            if (depth?.coordinates) {
              return (
                <Marker
                  icon={markerIcon}
                  key={depth.id}
                  position={[
                    depth?.coordinates[0],
                    depth?.coordinates[1],
                  ]}
                  eventHandlers={{
                    click: () => {
                      setDepthMarker(depth);
                    },
                  }}
                />
              );
            }
            return null;
          },
        )
      ) : null} */}
      {/* add markers for depth */}
      {/* {depthData && depthLayer
        ? depthData.map((depth) => {
          if (depth?.coordinates) {
            return (
              <Marker
                icon={markerIcon}
                key={depth.id}
                position={[depth?.coordinates[0], depth?.coordinates[1]]}
                eventHandlers={{
                  click: () => {
                    setDepthMarker(depth);
                  },
                }}
              />
            );
          }
          return null;
        })
        : null} */}
      {/* add popup for clicked depth marker */}
      {/* add markers for AIS */}
      {/* {aisData && aisLayer
        ? aisData.map((ais) => {
          if (ais && ais.coordinates) {
            return (
              <Marker
                icon={markerIcon}
                key={ais.id}
                position={[ais?.coordinates[0], ais?.coordinates[1]]}
                eventHandlers={{
                  click: () => {
                    setAisMarker(ais);
                  },
                }}
              />
            );
          }
          return null;
        })
        : null} */}
      {/* add popup for clicked AIS marker */}
      {aisMarker  && aisMarker.coordinates && (
        <Popup
          position={[aisMarker?.coordinates[0], aisMarker?.coordinates[1]]}
        >
          <Box>
            {aisMarker.description ? (
              <Box as="p">AIS: aisMarker.description </Box>
            ) : null}
            <Box as="p">
              ID:
              {aisMarker.id}
            </Box>
          </Box>
        </Popup>
      )}
      {/* add path layer */}
      {pathLayer && (path && (<Polyline positions={path} color="#efebef" />))}
      {actualPathLayer && (actualPath && (<Polyline positions={actualPath} />))}
      {depthLayer && depthMarkers}
      {/* add marker for asv location */}
      {asvLocation && (
        <Marker
          key={asvLocation[3]}
          position={[asvLocation[0], asvLocation[1]]}
          icon={boatIcon}
        />
      )}
    </MapContainer>
  );
};

Map.defaultProps = MapDefaultProps;

export default Map;
