import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import {
  Box, Button, Heading, Icon, Text, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { STORE_USER } from '../../actions';
import { MdLogout } from 'react-icons/md';
import Wrapper from '../../components/wrapper';
import AuthService from '../../services/auth.service';
import CancelButton from '../../components/cancelButton';
import { isUserAuthenticated } from '../../utils/helpers';
import { generalErrorToast } from '../../utils/sharedObjects';
import { IRootState } from '../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../components/pageWrapper';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const user = useSelector((state: IRootState) => state.user);

  useEffect(() => {
    if (!isUserAuthenticated(user)) {
      navigate('/');
    }
  }, [navigate, user]);

  const logout = async () => {
    try {
      await AuthService.logout();
      dispatch({type: STORE_USER, payload: ''})
      navigate('/login');
    } catch (error : any) {
      if (!error?.message?.includes('401')) {
        toast(generalErrorToast());
      }
    }
    navigate('/login');
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Box pl="15px">
          <Heading size="lg">Logout</Heading>
        </Box>
      </Wrapper>
      <Wrapper variant="small">
        <Formik onSubmit={logout} initialValues={{}}>
          {({ isSubmitting }) => (
            <Form>
              <Text>You may log out now by clicking the button below</Text>
              <Button
                marginTop="15px"
                type="submit"
                colorScheme="orange"
                leftIcon={<Icon as={MdLogout} mb="-3px" />}
                isLoading={isSubmitting}
              >
                {' '}
                Logout
              </Button>
              <CancelButton />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PageWrapper>
  );
}

export default Logout;
