import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  Box, Heading, Table, Thead, Tbody, Tr, Th, Td, TableCaption, Text,
  SimpleGrid, Spacer, Flex, CircularProgress, useToast,
} from '@chakra-ui/react';
import {
  forwardUnauthenticatedUser, deleteRequestHandle, getRequestHandle,
} from '../../../utils/helpers';
import Wrapper from '../../../components/wrapper';
import DeleteButton from '../../../components/deleteButton';
import EditButton from '../../../components/editButton';
import CreateButton from '../../../components/createButton';
import UserService from '../../../services/user.service';
import { IUser } from '../../../interfaces/user';
import {
  deleteSuccessToast, generalErrorToast, deleteFailToast,
} from '../../../utils/sharedObjects';
import { IRootState } from '../../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../../components/pageWrapper';

const ListUsers = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const currentUser = useSelector((state: IRootState) => state.user);

  const [users, setUsers] = useState<IUser[]>([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    forwardUnauthenticatedUser(currentUser);

    const getUsers = async () => {
      const response = await UserService.getAll();
      const getResponse = getRequestHandle(response);

      if (getResponse && response.data) {
        setIsLoading(false);
        setUsers(response.data);
      }
    };

    getUsers().catch((e) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });
  }, [currentUser, toast]);

  const deleteUser = async (userId: number) => {
    setIsDeleteLoading(true);
    const response = await UserService.deleteOneById(userId);
    const deleteResponse = deleteRequestHandle(response);
    if (deleteResponse) {
      toast(deleteSuccessToast('User'));
      setUsers(users.filter((user) => user.id !== userId));
    } else {
      toast(deleteFailToast('User'));
    }
    setIsDeleteLoading(false);
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Users</Heading>
          </Box>
          <Spacer />
          <Box>
            <CreateButton
              title="Add user"
              route="/admin/users/add"
            />
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="medium">
        <Box>
          <Table variant="simple">
            <TableCaption><Text as="i">All users</Text></TableCaption>
            <Thead>
              <Tr>
                <Th isNumeric>ID</Th>
                <Th>Firstname</Th>
                <Th>Surname</Th>
                <Th>Email</Th>
                <Th>IsActive</Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading
                ? (
                  <Tr>
                    <Td colSpan={2} />
                    <Td colSpan={3}>
                      <CircularProgress isIndeterminate color="teal.500" />
                    </Td>
                  </Tr>
                )
                : null}
              {
                users.map((user: IUser) => (
                  <Tr key={user.id}>
                    <Td isNumeric>{user.id}</Td>
                    <Td>{user.firstName}</Td>
                    <Td>{user.surname}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.isActive !== undefined ? user.isActive.toString() : 'false'}</Td>
                    <Td>
                      <SimpleGrid columns={2}>
                        <Box>
                          <DeleteButton
                            title="delete user"
                            isDisabled={isDeleteLoading}
                            onClick={() => deleteUser(user.id)}
                          />
                        </Box>
                        <Box>
                          <EditButton
                            title="edit user"
                            onClick={() => navigate(`/admin/users/${user.id}`)}
                          />
                        </Box>
                      </SimpleGrid>
                    </Td>
                  </Tr>
                ))
              }
            </Tbody>
          </Table>
        </Box>
      </Wrapper>
    </PageWrapper>
  );
}

export default ListUsers;
