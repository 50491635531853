import { Axios, AxiosResponse } from 'axios';
import CrudService from './crud.service';
import api from '../utils/api';

class VesselService extends CrudService {
  constructor() {
    super('vehicles');
  }

  getStatusLog(vesselId: number): Promise<AxiosResponse> {
    return api.get(`/${this.base}/${vesselId}/status-log`);
  }

  getVesselUsers(vesselId: number): Promise<AxiosResponse> {
    return api.get(`/${this.base}/${vesselId}/users`);
  }

  async updateVesselUsers(
    vesselId: number,
    assignedUsers: { userId: number }[],
  ): Promise<AxiosResponse> {
    return api.post(`/${this.base}/${vesselId}/users`, assignedUsers);
  }

  getLatestMissions(vesselId: number) {
    return api.get(`/${this.base}/${vesselId}/missions`);
  }

  getOneWithMissions(vesselId: number) {
    return api.get(`/${this.base}/${vesselId}`);
  }

  getVesselStatus(vesselId: number): Promise<AxiosResponse> {
    return api.get(`/${this.base}/${vesselId}/status`);
  }
  
  getVesselWithMission(vesselId: number, missionId: number) {
    return api.get(`/${this.base}/${vesselId}/m/${missionId}`);
  }
}

export default new VesselService();
