import React, { ReactElement } from 'react';
import { CSSObject, Icon, IconButton } from '@chakra-ui/react';
import { MdOutlineEdit } from 'react-icons/md';

type ButtonProps = {
  title: string;
  ariaLabel?: string;
  color?: string;
  bgColor?: string;
  _hover?: CSSObject;
  _active?: CSSObject;
  icon?: ReactElement;
  colorScheme?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonDefaultProps = {
  ariaLabel: '',
  color: '',
  bgColor: '',
  _hover: {},
  _active: {},
  icon: undefined,
  colorScheme: '',
  onClick: undefined,
};

const EditButton: React.FC<ButtonProps> = ({ ...props }) => {
  if (props.color && props.bgColor && props._hover && props._active) {
    return (
      <IconButton
        aria-label={props.ariaLabel ? props.ariaLabel : props.title}
        bgColor={props.bgColor ? props.bgColor : 'blue.300'}
        _hover={props._hover ? props._hover : { bg: 'blue.400', color: 'gray.100' }}
        _active={props._active ? props._active : { bg: 'blue.500', color: 'gray.200' }}
        _focus={{ outline: 'none' }}
        color={props.color ? props.color : 'white'}
        title={props.title}
        icon={props.icon ? props.icon : <Icon as={MdOutlineEdit} />}
        onClick={props.onClick ? props.onClick : undefined}
      />
    );
  }
  return (
    <IconButton
      aria-label={props.ariaLabel ? props.ariaLabel : props.title}
      colorScheme="blue"
      title={props.title}
      icon={props.icon ? props.icon : <Icon as={MdOutlineEdit} />}
      onClick={props.onClick ? props.onClick : undefined}
    />
  );
};

EditButton.defaultProps = ButtonDefaultProps;

export default EditButton;
