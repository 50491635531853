import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MdOutlineFormatListBulleted, MdSave } from 'react-icons/md';
import {
  Box, Button, Flex, FormLabel, Heading, Icon, SimpleGrid, Spacer, Switch, useToast, Text,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import MissionPathBuilder from '../../components/missionPathBuilder';
//@ts-ignore
import RRuleGenerator from 'react-rrule-generator-tt';
import CancelButton from '../../components/cancelButton';
import InputField from '../../components/inputField';
import Wrapper from '../../components/wrapper';
import {
  forwardUnauthenticatedUser, rruleToCron, createRequestHandle, getRequestHandle,
} from '../../utils/helpers';
import { IMission } from '../../interfaces/mission';
import MissionService from '../../services/mission.service';
import {
  createSuccessToast, createFailToast, generalErrorToast,
} from '../../utils/sharedObjects';
import { initialMission } from '../../utils/initialValues';
import UserService from '../../services/user.service';
import LocationService from '../../services/location.service';
import { IRootState } from '../../interfaces/rootState';
import PageWrapper from '../../components/pageWrapper';

const AddMission = () => {
  const navigate = useNavigate();
  const toast = useToast();

  type VesselDefault = {
    value: number, 
    label: string,
  };

  const currentUser = useSelector((state: IRootState) => state.user);
  const [mission] = useState(initialMission);
  const selectedVessel = useSelector((state: IRootState) => state.vessel);
  const [toggle, setToggle] = useState(true);
  const [adhocState, setAdhocState] = useState(false);
  const [missionPath, setMissionPath] = useState([]);
  const [scheduledAt, setScheduledAt] = useState('');
  const [rruleString, setRruleString] = useState('RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=1');
  const [recurrencyPattern, setRecurrencyPattern] = useState('0 0 1 * ?');
  const [isRecurrentMission, setIsRecurrentMission] = useState(false);
  const [availableVessels, setAvailableVessels] = useState([]);
  const [vesselDefault, setVesselDefault] = useState<VesselDefault | undefined>(undefined);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [vehicleId, setVehicleId] = useState(0);
  const [locationId, setLocationId] = useState(0);

  useEffect(() => {
    forwardUnauthenticatedUser(currentUser);

    const getAvailableVessels = async () => {
        const response = await UserService.getOneById(currentUser.id);
        if (getRequestHandle(response)) {
          setAvailableVessels(response.data.userVehicles.map((vessel : any) => ({
            value: vessel.vehicle.id.toString(10),
            label: `${vessel.vehicle.name}`,
          })));
        } else if (response.status === 404) {
          navigate('/404');
        }
    };

    const getAvailableLocations = async () => {
        const response = await LocationService.getAll();
        if (response.status === 200) {
          setAvailableLocations((response.data.map((locationItem : any) => ({
            value: locationItem.id.toString(10),
            label: `${locationItem.name}`,
          }))));
        } else if (response.status === 404) {
          navigate('/404');
        }
      
    };

    getAvailableVessels().catch((e: Error) => {
      // only display general error if it was not a 404
      if (!e.message.includes('404')) {
        toast(generalErrorToast());
      }
    });

    getAvailableLocations().catch((e: Error) => {
      // only display general error if it was not a 404
      if (!e.message.includes('404')) {
        toast(generalErrorToast());
      }
    });
  }, [navigate, currentUser, toast]);

  useEffect(() => {
    if (vesselDefault) {
      setVehicleId(vesselDefault.value);
    }

    if (selectedVessel && availableVessels.length > 0) {
      const d = availableVessels.find(({ value }) => value === selectedVessel.id.toString(10));
      setVesselDefault(d);
    }
  }, [selectedVessel, availableVessels, vesselDefault]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleAdhoc = () => {
    setAdhocState(!adhocState);
  };

  const switchRecurrentMission = () => {
    setIsRecurrentMission(!isRecurrentMission);
  };

  const fileMissionPathSetter = (uploadedPath : any) => {
    setMissionPath(uploadedPath);
  };

  const scheduledAtSetter = ({ target: { value } } : any) => {
    setScheduledAt(value);
  };

  const save = async (formData: { mission: IMission }) => {
    const newData = formData.mission;
    newData.active = toggle;
    newData.adhoc = adhocState;
    if (isRecurrentMission) {
      newData.recurrencyPattern = recurrencyPattern;
      newData.rrule = rruleString;
    }
    newData.scheduledAt = (new Date(scheduledAt)).toString();
    // TODO: we probably want to do that earlier
    newData.missionPath = missionPath;
    newData.vehicleId = vehicleId;
    newData.locationId = locationId;
    const response = await MissionService.addOne(newData);
    const createResponse = createRequestHandle(response);
    if (createResponse) {
      toast(createSuccessToast('Mission'));
      navigate('/missions');
    } else {
      toast(createFailToast('Mission'));
    }
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Add Mission</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              type="submit"
              colorScheme="teal"
              onClick={() => navigate('/missions')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              List missions
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="large">
        <FormLabel htmlFor="mission.missionPath">Mission Path</FormLabel>
        <MissionPathBuilder
          page="add"
          dbMissionPath={[]}
          fileMissionPath={fileMissionPathSetter}
          style={{ height: '280px', width: '100wh' }}
        />
        <Wrapper variant="large">
          <Formik
            initialValues={{ mission }}
            onSubmit={save}
            enableReinitialize
          >
            {({ isSubmitting }) => (
              <Form>
                <SimpleGrid columns={2} spacing={5} mb="5">
                  <Box>
                    <InputField
                      name="mission.id"
                      required
                      type="hidden"
                    />
                    <Box marginTop="15px">
                      <InputField
                        label="Name"
                        name="mission.name"
                        type="text"
                      />
                    </Box>
                    <Box marginTop="15px">
                      <InputField
                        label="Scheduled At"
                        name="mission.scheduledAt"
                        required
                        type="datetime-local"
                        value={scheduledAt}
                        onChange={(e) => scheduledAtSetter(e)}
                      />
                    </Box>
                    <Box marginTop="15px">
                      <InputField
                        label="Estimated length (minutes)"
                        name="mission.estimatedLength"
                        type="text"
                        required
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Box marginTop="15px">
                      <Text>
                        Vessel
                      </Text>
                      { availableVessels && vesselDefault && (
                        <Box marginTop="9px">
                          <Select
                            options={availableVessels}
                            defaultValue={vesselDefault}
                            menuPlacement="auto"
                            onChange={(e) => {
                              // @ts-ignore
                              setVehicleId(e.value);
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                    <Box marginTop="15px">
                      <Text>
                        Location
                      </Text>
                      <Box marginTop="9px">
                        <Select
                          options={availableLocations}
                          menuPlacement="auto"
                          onChange={(e : any) => {
                            setLocationId(e.value);
                          }}
                        />
                      </Box>
                    </Box>
                    <SimpleGrid column={2} spacing={10} style={{ display: 'inline-flex' }}>
                      <Box marginTop="15px">
                        <FormLabel htmlFor="mission.active">Active</FormLabel>
                        <Switch
                          pt="10px"
                          id="mission.active"
                          name="mission.active"
                          colorScheme="teal"
                          isChecked={toggle}
                          onChange={handleToggle}
                          size="lg"
                        />
                      </Box>
                      <Box marginTop="15px">
                        <FormLabel htmlFor="mission.active">AdHoc</FormLabel>
                        <Switch
                          pt="10px"
                          id="mission.adhoc"
                          name="mission.adhoc"
                          colorScheme="teal"
                          isChecked={adhocState}
                          onChange={handleAdhoc}
                          size="lg"
                        />
                      </Box>
                      <Box marginTop="15px">
                        <FormLabel htmlFor="isRecurrentMission">Recurrent</FormLabel>
                        <Switch
                          pt="10px"
                          id="isRecurrentMission"
                          name="isRecurrentMission"
                          colorScheme="teal"
                          isChecked={isRecurrentMission}
                          onChange={switchRecurrentMission}
                          size="lg"
                        />
                      </Box>
                    </SimpleGrid>
                  </Box>
                </SimpleGrid>
                {isRecurrentMission
                  ? (
                    <>
                      <Box>
                        <InputField
                          label="Scheduled Until"
                          name="mission.scheduledUntil"
                          min={scheduledAt}
                          required
                          type="datetime-local"
                        />
                      </Box>
                      <Box marginTop="15px">
                        <RRuleGenerator
                          onChange={(rrule : any) => {
                            const cron = rruleToCron(
                              rrule,
                              new Date(scheduledAt).getHours(),
                              new Date(scheduledAt).getMinutes(),
                            );
                            setRecurrencyPattern(cron);
                            setRruleString(rrule);
                          }}
                          config={{
                            repeat: ['Monthly', 'Weekly', 'Daily', 'Yearly'],
                            hideEnd: true,
                          }}
                          value={rruleString}
                        />
                      </Box>
                    </>
                  )
                  : <Box />}
                <Button
                  marginTop="15px"
                  type="submit"
                  colorScheme="teal"
                  isLoading={isSubmitting}
                >
                  <Icon as={MdSave} mb="-2px" mr="5px" />
                  Save
                </Button>
                <CancelButton />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </Wrapper>
    </PageWrapper>
  );
}

export default AddMission;
