import api from '../utils/api';
import { IMission } from '../interfaces/mission';
import { IUser } from '../interfaces/user';
import { IVessel } from '../interfaces/vessel';
import { IVehicleSubsystem } from '../interfaces/vehicleSubsystem';
import { ILocation } from '../interfaces/location';

export default class CrudService {
  public base: string;

  constructor(base: 'missions' | 'users' | 'vehicles' | 'location' | 'subsystems') {
    if (!base) {
      throw Error('Base url parameter is required!');
    }

    this.base = base;
  }

  getAll() {
    return api.get(`/${this.base}`);
  }

  getOneById(id: number) {
    return api.get(`/${this.base}/${id}`);
  }

  addOne(data: IMission | IUser | IVessel | IVehicleSubsystem | ILocation) {
    return api.post(`/${this.base}`, data);
  }

  updateOne(id: number, data : any) {
    return api.put(`/${this.base}/${id}`, data);
  }

  deleteOneById(id: any) {
    return api.delete(`/${this.base}/${id}`);
  }
}
