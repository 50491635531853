import api from '../utils/api';

class AuthService {
  public static login(data : any) {
    return api.post('/login',data);
  }

  public static logout() {
    return api.get('/logout');
  }
}

export default AuthService;
