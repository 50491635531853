import './App.css';
import { useEffect, useState} from 'react';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { ChakraProvider, CircularProgress } from '@chakra-ui/react';
import { IRootState } from './interfaces/rootState';
import Navbar from './components/navbar';
import Footer from './components/footer';
import { forwardUnauthenticatedUser } from './utils/helpers';
import React from 'react';
import Main from './Main';

const App = () => {

  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector((state: IRootState) => state.user);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const progressStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  };


  useEffect(() => {
    console.log(pathname)
    if (matchPath({path: '/'}, pathname)){
      forwardUnauthenticatedUser(currentUser, navigate);
    }

    if (window.location.href.indexOf('/login') > -1 || currentUser.id) {
      setIsLoading(false);
    }
  }, [navigate, pathname, currentUser]);

  return (
    <>
        <ChakraProvider>
          {isLoading ? (
            <div style={progressStyle}>
              <CircularProgress isIndeterminate />
            </div>
          ) : (
            <>
              <Navbar />
              <Main />
            </>
          )}
          <Footer />
        </ChakraProvider>
    </>
  );
}

export default App;
