import React from 'react';
import { Box } from '@chakra-ui/react';

interface WrapperProps {
  children: any,
  variant?: 'small' | 'medium' | 'large'
}

const Wrapper: React.FC<WrapperProps> = ({ children, variant = 'medium' }) => {
  let width;

  switch (variant) {
    case 'large':
      width = '1000px';
      break;
    case 'medium':
      width = '800px';
      break;
    case 'small':
    default:
      width = '400px';
      break;
  }

  return (
    <Box marginTop="15px" marginX="auto" maxWidth={width} width="100%">
      {children}
    </Box>
  );
};

export default Wrapper;
