import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { MdOutlineFormatListBulleted, MdSave } from 'react-icons/md';
import {
  Box, Button, Flex, FormLabel, Heading, Icon, Spacer, Switch, useToast, Text,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import Select from 'react-select';
import CancelButton from '../../../components/cancelButton';
import InputField from '../../../components/inputField';
import Wrapper from '../../../components/wrapper';
import {
  forwardUnauthenticatedUser, updateRequestHandle, getRequestHandle, createRequestHandle,
} from '../../../utils/helpers';
import UserService from '../../../services/user.service';
import VesselService from '../../../services/vessel.service';
import { IUser } from '../../../interfaces/user';
import { IVessel } from '../../../interfaces/vessel';
import {
  generalErrorToast, updateFailToast, updateSuccessToast,
} from '../../../utils/sharedObjects';
import { initialUser } from '../../../utils/initialValues';
import { IRootState } from '../../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../../components/pageWrapper';

const EditUser = () => {
  const toast = useToast();
  const { userId } = useParams();

  const navigate = useNavigate();

  type AvailableVessels = {
    label: string, 
    value: string,
  }

  type AssignedVessels = {
    vehicleId: number,
    vesselId: number,
  }

  const currentUser = useSelector((state: IRootState) => state.user);
  const [user, setUser] = useState(initialUser);
  const [toggle, setToggle] = useState(true);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [userRoleId, setUserRoleId] = useState(0);
  const [vesselOptions, setVesselOptions] = useState<any[]>([]);
  const [availableVessels, setAvailableVessels] = useState<AvailableVessels[]>([]);
  const [assignedVessels, setAssignedVessels] = useState<AssignedVessels[]>([]);

  useEffect(() => {
    forwardUnauthenticatedUser(currentUser);

    const getUser = async () => {
      if (Number.isNaN(Number(userId))) {
        navigate('/404');
        // this makes sure we run below code only once
      } else if (userId !== undefined) {
        const response = await UserService.getOneById(+userId);
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setUser(response.data);
          setToggle(response.data.isActive);
          setUserRoleId(response.data.userRoleId);
        } else {
          navigate('/404');
        }
      }
    };

    const getAvailableUsers = async () => {
        const response = await UserService.getAllUserRoles();
        if (response.status === 200) {
          setAvailableUsers(response.data.map((userItem : any) => ({
            value: userItem.id.toString(10),
            label: `${userItem.role}`,
          })));
        } else if (response.status === 404) {
          navigate('/404');
        }
    };

    const getAvailableVessels = async () => {
        const response = await VesselService.getAll();
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          let myVessels: Array<{value: string, label: string}> = [];
          response.data.forEach((element : IVessel) => {
            myVessels.push(
              {
                value: element.id.toString(10), 
                label: `${element.name}`,
              }
            )
          });
          setAvailableVessels(myVessels);
          setVesselOptions(myVessels);
          // setAvailableVessels(response.data.map((availableVessel: IVessel) => ({
          //   value: availableVessel.id.toString(10), label: `${availableVessel.name}`,
          // })));
        } else {
          navigate('/404');
        }
    };

    const getAssignedVessels = async () => {
      if (Number.isNaN(Number(userId))) {
        navigate('/404');
        // this makes sure we run below code only once
      } else if (userId !== undefined){
        const response = await UserService.getUserVessels(+userId);
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          
          setAssignedVessels(response.data);
        } else {
          navigate('/404');
        }
      }
    };

    getUser().catch((e) => {
      // only display general error if it was not a 404
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });

    getAvailableUsers().catch((e) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });

    getAvailableVessels().catch((e: Error) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });

    getAssignedVessels().catch((e: Error) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });
  }, [navigate, currentUser, userId, toast]);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const getSelectDefaultUser = () => availableUsers
    .filter(({ value }) => value === userRoleId.toString());

  const getVesselsSelectDefaultValue = () => { 
    console.log(availableVessels)
    console.log(assignedVessels)
    let myVessels = availableVessels
    .filter(( { value }  ) => assignedVessels.some(( { vesselId } ) => value === vesselId.toString()))
    .map(({ value, label } : any) => ({ label: label, value: value }));
    console.log(myVessels)
    return myVessels;
  }

  const onVesselSelectChange = async (selectedValues: any) => {
    const newAssignedVessels = selectedValues.map(({ value, label } : any) => ({ label: label, value: value }));
    setAssignedVessels(newAssignedVessels);
    const response = await UserService.updateUserVessels(user.id, newAssignedVessels);
    const createResponse = createRequestHandle(response);
    if (createResponse) {
      toast(updateSuccessToast(`Vessels of user ${currentUser.firstName}`));
    } else {
      toast(updateFailToast(`Vessels of user ${currentUser.firstName}`));
    }
  };

  const update = async (changedUser: { user: IUser }) => {
    const newData = changedUser.user;
    newData.isActive = toggle;
    newData.userRoleId = userRoleId;
    let vesselIds : number[] = [];
    assignedVessels.forEach(vessel => {
      vesselIds.push(vessel.vehicleId)
    });
    newData.vesselIds = vesselIds;
    // TODO: what are we really allowed to update here?
    delete newData.password;
    delete newData.updatedAt;
    delete newData.deletedAt;
    delete newData.createdAt;
    const response = await UserService.updateOne(newData.id, newData);
    const updateResponse = updateRequestHandle(response);
    if (updateResponse) {
        toast(updateSuccessToast('User'));
        navigate('/admin/users');
    } else {
      toast(updateFailToast('User'));
    }
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Edit User</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              type="submit"
              colorScheme="teal"
              onClick={() => navigate('/admin/users')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              List users
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="small">
        <Formik
          initialValues={{ user }}
          onSubmit={update}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField
                name="user.id"
                required
                type="hidden"
              />
              <InputField
                label="Firstname"
                name="user.firstName"
                required
                type="text"
              />
              <Box marginTop="15px">
                <InputField
                  label="Surname"
                  name="user.surname"
                  type="text"
                  required
                />
              </Box>
              <Box marginTop="15px">
                <InputField
                  label="Email"
                  name="user.email"
                  type="email"
                  required
                />
              </Box>
              <Box marginTop="15px">
                <Text>
                  User Role
                </Text>
                <Select
                  options={availableUsers}
                  menuPlacement="auto"
                  defaultValue={getSelectDefaultUser}
                  key={getSelectDefaultUser().toString()}
                  // @ts-ignore
                  onChange={({ value }) => setUserRoleId(value)}
                />
              </Box>
              <Box marginTop="15px">
                <FormLabel htmlFor="user.isActive">Active?</FormLabel>
                <Switch
                  id="user.isActive"
                  name="user.isActive"
                  colorScheme="teal"
                  isChecked={toggle}
                  onChange={handleToggle}
                />
              </Box>
              <Text pb="10px">
                Select vessel(s) for this user
              </Text>
              <Select
                options={vesselOptions}
                defaultValue={getVesselsSelectDefaultValue}
                menuPlacement="auto"
                isMulti
                onChange={onVesselSelectChange}
                key={getVesselsSelectDefaultValue().toString()}
                maxMenuHeight={150}
              />
              <Button
                marginTop="15px"
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
              >
                <Icon as={MdSave} mb="-2px" mr="5px" />
                Save
              </Button>
              <CancelButton />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PageWrapper>
  );
}

export default EditUser;
