import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  Box, Heading, SimpleGrid, Spacer, Flex, useToast, Spinner,
} from '@chakra-ui/react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { forwardUnauthenticatedUser, deleteRequestHandle } from '../../utils/helpers';
import Wrapper from '../../components/wrapper';
import DeleteButton from '../../components/deleteButton';
import EditButton from '../../components/editButton';
import MissionService from '../../services/mission.service';
import { IMission } from '../../interfaces/mission';
import { deleteSuccessToast, generalErrorToast, deleteFailToast } from '../../utils/sharedObjects';
import ViewButton from '../../components/viewButton';
import CreateButton from '../../components/createButton';
import { IRootState } from '../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../components/pageWrapper';

const ListMissions = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);
  const selectedVessel  = useSelector((state: IRootState) => state.vessel);
  const [prevSelectedVesselId, setPrevSelectedVesselId] = useState(0);
  const [missions, setMissions] = useState([] as IMission[]);
  const [passedMissions, setPassedMissions] = useState([] as IMission[]);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadMission, setReloadMissions] = useState(false);

  useEffect(() => {
    forwardUnauthenticatedUser(user);

    const getMissions = async () => {
      const response = await MissionService.getAll();
      if (response?.data) {
        setReloadMissions(false);
        setMissions(response.data.filter(
          (mission: IMission) => moment(mission.scheduledAt).isAfter(moment())
          && !mission.endedAt,
        ));
        setPassedMissions(response.data.filter(
          (mission: IMission) => moment(mission.scheduledAt).isBefore(moment())
          || mission.endedAt,
        ));
        if (selectedVessel) {
          setPassedMissions((m) => m.filter(
            (mission: IMission) => mission.vehicleId === selectedVessel.id,
          ));
          setMissions((m) => m.filter(
            (mission: IMission) => mission.vehicleId === selectedVessel.id,
          ));
        }
      }
      setIsLoading(false);
    };

    if (prevSelectedVesselId !== selectedVessel?.id) {
      setPrevSelectedVesselId(selectedVessel?.id);
      setReloadMissions(true);
    } else {
      setReloadMissions(false);
    }

    if (reloadMission) {
      getMissions().catch((e) => {
        if (!e.message.includes('404') && !e.message.includes('401')) {
          toast(generalErrorToast());
          console.error(e.message);
        }
      });
    }
  }, [user, toast, selectedVessel,  prevSelectedVesselId, reloadMission]);

  const deleteMission = async (missionId : any) => {
    const response = await MissionService.deleteOneById(missionId);
    const deleteResponse = deleteRequestHandle(response);
    if (deleteResponse) {
      toast(deleteSuccessToast('Mission'));
      setMissions(missions.filter((mission) => mission.id !== missionId));
      setPassedMissions(passedMissions.filter((mission) => mission.id !== missionId));
    } else {
      toast(deleteFailToast('Mission'));
    }
  };

  const scheduledMissionButtons = (row: IMission) => (
    <SimpleGrid columns={2} spacing={5}>
      <Box>
        <DeleteButton
          title="delete mission"
          onClick={() => deleteMission(row.id)}
        />
      </Box>
      <Box>
        <EditButton
          title="edit mission"
          onClick={() => navigate(`/missions/${row.id}`)}
        />
      </Box>
    </SimpleGrid>
  );
  const scheduledMissionColumns = [
    {
      name: 'Name',
      selector: (row: IMission) => row.name ?? '',
    },
    { name: 'Vessel', selector: (row: IMission) => row.vesselName ?? '' },
    {
      name: 'Scheduled Start',
      selector: (row: IMission) => (row.scheduledAt ? moment(row.scheduledAt).format('DD.MM.yyyy HH:mm:ss') : 'no data'),
    },
    {
      name: 'Scheduled End',
      selector: (row: IMission) => (row.scheduledUntil ? moment(row.scheduledUntil).format('DD.MM.yyyy HH:mm:ss') : 'no data'),
    },
    {
      name: 'Type',
      selector: (row: IMission) => (row.recurrencyPattern ? 'recurrent' : 'one time'),
    },
    {
      name: 'Action',
      cell: (row: IMission) => scheduledMissionButtons(row),
    },
  ];

  const passedMissionButtons = (row: IMission) => (
    <SimpleGrid columns={2} spacing={5}>
      <Box>
        <DeleteButton
          title="delete mission"
          onClick={() => deleteMission(row.id)}
        />
      </Box>
      <Box>
        <ViewButton
          title="view details"
          onClick={() => navigate(`/missions/${row.id}/details`)}
        />
      </Box>
    </SimpleGrid>
  );

  const passedMissionColumns = [
    {
      name: 'Name',
      selector: (row: IMission) => row.name ?? '',
    },
    { name: 'Vessel', selector: (row: IMission) => row.vesselName ?? '' },
    { name: 'Location', selector: (row: IMission) => row.location?.name ?? '' },
    {
      name: 'Started At',
      selector: (row: IMission) => (row.startedAt ? moment(row.startedAt).format('DD.MM.yyyy HH:mm:ss') : 'no data'),
    },
    {
      name: 'Ended At',
      selector: (row: IMission) => (row.endedAt ? moment(row.endedAt).format('DD.MM.yyyy HH:mm:ss') : 'no data'),
    },
    {
      name: 'Action',
      cell: (row: IMission) => passedMissionButtons(row),
    },
  ];

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="md">Mission History</Heading>
          </Box>
          <Spacer />
          <Box>
            <CreateButton
              title="Add mission"
              route="/missions/add"
            />
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="large">
        <DataTable
          columns={passedMissionColumns}
          data={passedMissions}
          pagination
          paginationPerPage={20}
          onRowClicked={(row) => navigate(`/missions/${row.id}/details`)}
          progressPending={isLoading}
          progressComponent={<Spinner size="lg" />}
        />
      </Wrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="md">Scheduled Missions</Heading>
          </Box>
          <Spacer />
        </Flex>
      </Wrapper>
      <Wrapper variant="large">
        <DataTable
          columns={scheduledMissionColumns}
          data={missions.sort((a, b) => ((a.scheduledAt > b.scheduledAt) ? 1 : -1))}
          pagination
          paginationPerPage={20}
          onRowClicked={(row: IMission) => navigate(`/missions/${row.id}`)}
          progressPending={isLoading}
          progressComponent={<Spinner size="lg" />}
        />
      </Wrapper>
    </PageWrapper>
  );
}

export default ListMissions;
