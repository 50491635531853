/* eslint-disable react/function-component-definition */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Spacer,
  HStack,
  Center,
} from '@chakra-ui/react';
import { MdLogout, MdPlayCircle } from 'react-icons/md';
import { IoIosBoat } from 'react-icons/io';
import { FaUserCog } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import Select, { SingleValue } from 'react-select';
import { useNavigate, Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { STORE_VESSEL, STORE_VESSELS, STORE_MISSION, STORE_USER } from '../actions';
import {
  forwardUnauthenticatedUser,
  getRequestHandle,
  isUserAuthenticated,
} from '../utils/helpers';
import { IVessel } from '../interfaces/vessel';
import VesselService from '../services/vessel.service';
import VesselStatusIcon from './vesselStatusIcon';
import { IMission } from '../interfaces/mission';
import { IRootState } from '../interfaces/rootState';

const NavBar: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const user = useSelector((state: IRootState) => state.user);
  const [vesselDropdown, setVesselDropdown] = useState<Array<any>>([]);
  const [missionDropdown, setMissionDropdown] = useState<Array<any>>([]);
  const [vesselList, setVesselList] = useState<IVessel[] | []>([]);
  const navigate = useNavigate();
  const selectInputRef = useRef<any>(null);
  const [missionList, setMissionList] = useState<IMission[] | []>([]);
  const [missionSelect, setMissionSelect] = useState({
    value: '-1',
    label: 'Loading...',
  });
  const [vesselSelect, setVesselSelect] = useState<SingleValue<{value: string, label: string}>>({
    value: '-1',
    label: 'Loading...',
  });
  const [vesselStatus, setVesselStatus] = useState({
    isError: false,
    description: 'Uknown',
    connection: 'Unknown',
  });
  const [vesselLocation, setVesselLocation] = useState('Loading...');
  const [loadMissionDropdown, setLoadMissionDropdown] = useState(false);
  const [activeMission, setActiveMission] = useState(
    useSelector((state: IRootState) => {
      if (state.vessel !== undefined && state.vessel.missions !== undefined){
        return state.vessel?.missions[0]?.active
      }}) || undefined
  );

  let selectedVessel  = useSelector((state: IRootState) => state.vessel);
  let selectedMission = useSelector((state: IRootState) => state.mission);
  let vessels = useSelector((state: IRootState) => state.vessels);

  console.log(pathname);
  const result = matchPath({path: '/'}, pathname);
  console.log(result )

  useEffect(() => {
    if (loadMissionDropdown) {
      const vesselMissions = selectedVessel?.missions;
      if (missionDropdown.length < 1) {
        setMissionDropdown(vesselMissions !== undefined ? vesselMissions?.map((mission) => ({
          value: `${mission.id.toString()}_${selectedVessel.id.toString()}`,
          label: mission.endedAt ? `${mission.name} - passed ${mission.endedAt}` : `${mission.name} - ongoing mission`,
        })) : []);
      }
      setLoadMissionDropdown(false);
    }
  }, [missionDropdown, loadMissionDropdown]);

  useEffect(() => {
      if (selectedVessel) {
        setVesselSelect({ value: selectedVessel.id.toString(), label: selectedVessel.name });
      }
      if (selectedMission) {
        setMissionSelect({
          value: selectedMission.id.toString(),
          label: selectedMission.endedAt ? `${selectedMission.name} - passed ${selectedMission.endedAt}` : `${selectedMission.name} - ongoing mission`,
        });
      }

  }, []);

  useEffect(() => {
    const getVessels = async () => {
      const response = await VesselService.getAll();
      if (getRequestHandle(response)) {
        setVesselDropdown(response?.data?.map((vesselData: { id: { toString: () => any; }; name: any; }) => ({
          value: vesselData.id.toString(),
          label: vesselData.name,
        })));
        dispatch({ type: STORE_VESSELS, payload: response.data})
        setVesselList(response.data);
      }
    };

     if (Object.keys(user).length > 0) {
       getVessels().catch((e) => {
        if(e.message.includes('401')){
          forwardUnauthenticatedUser(user, navigate, dispatch);
        }
        console.error(e);
       })
     }
  }, [user]);

  useEffect(() => {
    if ((vesselDropdown && vesselDropdown?.length > 0) && !selectedVessel && vessels !== undefined ) {
      const vessel = vessels.find(
        (vesselData) => vesselData !== undefined ? vesselData.id === Number(vesselDropdown[0].value) : undefined,
      );
      setVesselSelect(vesselDropdown[0]);
      dispatch({type: STORE_VESSEL, payload: vessel})
    } else if (selectedVessel) {
      setVesselSelect({ value: selectedVessel.id.toString(), label: selectedVessel.name });
    }
  }, [vesselDropdown]);

  useEffect(() => {
    const getMissions = async () => {
      if (vessels?.length > 0 && vesselList?.length > 0) {
        const findVessel = vessels?.find(
          (vesselData) => vesselData !== undefined ? vesselData.id === Number(vesselSelect !== null ? vesselSelect.value : '-1') : undefined,
        );
        setMissionList(findVessel !== undefined && findVessel.missions !== undefined ? findVessel?.missions : []);
        dispatch({type: STORE_VESSELS, payload: vessels})
      }
    };

    if ((vesselSelect && vesselSelect.value !== '-1')) {
      getMissions()
        .catch((error) => console.log(error))
        .finally();
    }
  }, [vesselDropdown, vesselSelect, vesselList]);

  useEffect(() => {
    if (missionList.length > 0) {
      setLoadMissionDropdown(true);
    }
  }, [missionList]);

  useEffect(() => {
    if (!selectedMission && missionList?.length > 0) {
      setMissionSelect({ value: missionList[0].id.toString(), label: missionList[0].name ?? '' });
      const mission = missionList?.find(
        (missionData) => missionData.id === Number(missionList[0].id),
      );
      dispatch({type: STORE_MISSION, payload: mission})
      if (mission?.active) {
        setActiveMission(true);
      }
    }
  }, [missionList]);

  const onVesselDropdownChange = async (selectedOption: SingleValue<{value: string, label: string}>) => {
    setVesselSelect(selectedOption);
    setMissionDropdown([]);
    if (!selectedOption) {
      // @ts-ignore
      selectInputRef?.current?.clearValue();
      return;
    }

    const selectedVessel = vessels?.find(
      (vesselData) => vesselData !== undefined ? vesselData.id === Number(selectedOption.value) : undefined,
    );
    dispatch({type: STORE_VESSEL, payload: selectedVessel})

    setLoadMissionDropdown(true);

    // @ts-ignore
    selectInputRef?.current?.clearValue();
  };

  const onMissionDropdownChange = (selectedOption: SingleValue<{value: string, label: string}>) => {
    if (!selectedOption) {
      dispatch({type: STORE_MISSION, payload: undefined})
      return;
    }

    if (Number(selectedOption.value) === -1) {
      dispatch({type: STORE_MISSION, payload: undefined})
     navigate('/missions');
      return;
    }
    if (vessels !== undefined){
      const foundVessel = vessels?.find(
        (vessel) => vessel !== undefined ? vessel.id === Number(selectedOption?.value?.split('_')[1]) : undefined,
      );
      if (foundVessel !== undefined && foundVessel.missions !== undefined){
        const selectedMission = foundVessel.missions.find((mission) => mission.id === Number(selectedOption?.value?.split('_')[0]));
        if (selectedMission !== undefined){
          setMissionSelect({ value: selectedMission.id.toString(), label: selectedMission.name ?? '' });
          dispatch({type: STORE_MISSION, payload: selectedMission})
        }
      }
  
    }

    navigate(`/missions/${selectedMission?.id}/details`);
  };

  const getVesselLastStatus = async (vesselId: number) => {
    const req = await VesselService.getVesselStatus(vesselId);
    return req.data[0];
  };

  const getVesselLastLocation = async (vesselId: number) => {
    const vesselMissions = await VesselService.getOneWithMissions(vesselId);
    return vesselMissions;
  };

  useEffect(() => {
    setVesselLocation('Loading...');
    if (isUserAuthenticated(user)) {
      const vesselId = selectedVessel?.id;
      if (vesselId) {
        getVesselLastLocation(vesselId).then((vesselData) => {
          setVesselLocation(vesselData.data.missions[0]?.location.name);
        });
        getVesselLastStatus(vesselId).then((data) => {
          if (data.state.isError) {
            setVesselStatus({ isError: true, description: 'Error', connection: data.dataConnectionType?.description });
          } else if (data.docked) {
            setVesselStatus({ isError: false, description: 'Charging', connection: data.dataConnectionType?.description });
          } else {
            setVesselStatus({
              isError: false,
              description: data.state.description,
              connection: data.dataConnectionType?.description,
            });
          }
        });
      }
    }
  }, [vesselSelect, user]);

  // display if user is logged out
  let navbarBody = (
    <Box />
  );

  const handleRealTimeButton = async () => {
    if (selectedVessel !== undefined && selectedVessel.missions !== undefined) {
      if (selectedVessel.missions[0]?.active) {
         navigate(`/vessels/${selectedVessel.id}/singleView`);
      }
    }
  };

  const selectCustomStyles = {
    option: (provided: any, state: { isSelected: any; }) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black',
    }),
    control: (provided: any) => ({
      ...provided,
      border: '1px solid white',
      borderRadius: '0px',
      boxShadow: 'none',
      fontSize: 14,
      '&:hover': {
        border: '1px solid white',
      },
      backgroundColor: 'rgb(128 128 128)',
      color: 'white',
    }),
    menu: (provided: any) => ({
      ...provided,
      border: '1px solid #d3d3d3',
      borderRadius: '0px',
      boxShadow: 'none',
      fontSize: 12,
      zIndex: '502',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
  };

  let dropDowns;
  let statusBar;
  // display if user is logged in
  if (isUserAuthenticated(user)) {
    navbarBody = (
      <>
        <Menu>
          <MenuButton mb="-3px" mr="10px" mt="25%">
            <FiSettings size="25" />
          </MenuButton>
          <MenuList zIndex="10000">
            <RouterLink to="/admin/users">
              <MenuItem mr={15} style={{ color: 'black' }}>
                  Users
              </MenuItem>
            </RouterLink>

            <RouterLink to="/admin/vessels">
              <MenuItem mr={15} style={{ color: 'black' }}>
                  Vessels
              </MenuItem>
            </RouterLink>

            <RouterLink to="/admin/locations">
              <MenuItem mr={15} style={{ color: 'black' }}>
                  Locations
              </MenuItem>
            </RouterLink>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton mb="-3px" mr="10px" mt="25%">
            <CgProfile size="25" />
          </MenuButton>
          <MenuList zIndex="10000">
            <RouterLink to="/user">
              <MenuItem mr={15} style={{ color: 'black' }}>
                  <Icon as={FaUserCog} mb="-3px" mr="4px" />
                  My profile
              </MenuItem>
            </RouterLink>

            <RouterLink to="/logout">
              <MenuItem mr={15} style={{ color: 'black' }}>
                  <Icon as={MdLogout} mb="-3px" mr="4px" />
                  Logout
              </MenuItem>
            </RouterLink>
          </MenuList>
        </Menu>
      </>
    );

    dropDowns = (
      <Box
        marginRight="15px"
        marginLeft="15px"
      >
        <SimpleGrid columns={3} spacing={10}>
          <Select
            options={vesselDropdown}
            //defaultValue={getSelectedVesselForDropdown}
            styles={selectCustomStyles}
            onChange={onVesselDropdownChange}
            key="vessel-top-menu-dropdown"
            isLoading={vesselDropdown.length === 0}
            value={vesselSelect}
          />
          <Select
            options={[
              ...(missionDropdown || []),
              { value: -1, label: 'Show all missions' },
            ]}
            styles={selectCustomStyles}
            onChange={onMissionDropdownChange}
            key={`mission-top-menu-dropdown-${missionDropdown?.toString()}`}
            isDisabled={missionDropdown?.length === 0}
            ref={selectInputRef}
            value={missionSelect}
            className="mission-dropdown"
          />
          <Button
            leftIcon={<MdPlayCircle />}
            onClick={handleRealTimeButton}
            isDisabled={!activeMission}
            className="realtime-button"
            colorScheme="facebook"
            size="md"
          >
            Real Time
          </Button>
        </SimpleGrid>
        <HStack spacing={10}>
          <Center>
            <RouterLink to="/">
              {/* <a className={matchPath({path: '/'}, pathname) ? 'active' : undefined}> */}
                Dashboard
                {/* </a> */}
            </RouterLink>
          </Center>
          <Center>
            <RouterLink to={`/vessels/${selectedVessel?.id}`}>
              {/* <a className={matchPath({path: '/vessels'}, pathname) ? 'active' : undefined}> */}
                Vessel
                {/* </a> */}
            </RouterLink>
          </Center>
          <Center>
            <RouterLink to="/missions">
              {/* <a className={matchPath({path: '/missions'}, pathname) ? 'active' : undefined}> */}
                Missions
                {/* </a> */}
            </RouterLink>
          </Center>
        </HStack>
      </Box>
    );

    if (matchPath({path: '/'}, pathname) === null  || vesselDropdown.length <= 1) {
      statusBar = (
        <Flex className="vessel-status-bar">
          <Box>
            Current Status:
            {' Driving'}
            {/* {vesselStatus.description} */}
          </Box>
          <Spacer />
          <Box>
            Location:
            {' '}
            {vesselLocation}
          </Box>
          <Spacer />
          <Box>
            <VesselStatusIcon
              isError={vesselStatus.isError}
              dataConnection={vesselStatus.connection}
            />
          </Box>
        </Flex>
      );
    }
  }

  return (
    <>
      <Flex padding="10px" backgroundColor="rgb(128 128 128)" color="white">
        <Box className="mindchip-logo">
          <Heading size="md">
            <RouterLink to="/">
                <Icon as={IoIosBoat} w="20" h="40px" mb="-3px" mr="5px" />
                MINDCHIP
            </RouterLink>
          </Heading>
        </Box>
        <Spacer />
        <Box w="50%" className="navbar-dropdowns">
          {matchPath({path: '/'}, pathname) !== null ? '' : dropDowns}
        </Box>
        <Spacer />
        <Box marginLeft="auto">
          {navbarBody}
        </Box>
      </Flex>
      {statusBar}
    </>
  );
};

export default NavBar;
