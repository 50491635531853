import { useState, useEffect } from 'react';
import {
  MapContainer, TileLayer, Polyline,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { generatePathFromFile } from '../utils/helpers';
import React from 'react';

const initialStyle = { height: '100vh', width: '100wh' };
// TODO: add iconRetinaUrl: '/marker-big.png' if retina screens are used in the future
// const markerIcon = new Icon({
//   iconUrl: '/marker-small.png',
//   shadowUrl: '/marker-shadow.png',
// });

export default function MissionPathBuilder({
  page, dbMissionPath, fileMissionPath, style = initialStyle,
} : any) {
  const [activePath, setPath] = useState([] as any);
  const {latlngs} : any = [];

  const onInputChange = async (e: any) => {
    const result = await generatePathFromFile(e);
    fileMissionPath(result);
    setPath(result);
  };

  useEffect(() => {
    if (page === 'edit') {
      if (Array.isArray(dbMissionPath)) {
        setPath(dbMissionPath);
      } else {
        setPath(JSON.parse(dbMissionPath));
      }
    }
  }, [page, dbMissionPath]);

  return (
    <div>
      <div>
        <input type="file" required name="mission.missionPath" onChange={onInputChange} />
      </div>
      <MapContainer
        center={[59.44241199628273, 24.75928028707022]}
        zoom={17}
        scrollWheelZoom
        style={style}

      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <TileLayer
          url="https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png"
          attribution='Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
        />
        {(activePath && Array.isArray(activePath))
          ? activePath.map((position) => latlngs.push([position[0], position[1]])) : null}
        ;
        {latlngs && (
          <Polyline
            positions={latlngs}
          />
        )}
        ;
      </MapContainer>
    </div>
  );
}
