import React, { ReactElement } from 'react';
import { CSSObject, Icon, IconButton } from '@chakra-ui/react';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

type ButtonProps = {
  title: string;
  ariaLabel?: string;
  color?: string;
  bgColor?: string;
  _hover?: CSSObject;
  _active?: CSSObject;
  icon?: ReactElement;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonDefaultProps = {
  ariaLabel: '',
  color: '',
  bgColor: '',
  _hover: {},
  _active: {},
  icon: undefined,
  isLoading: false,
  isDisabled: false,
  onClick: undefined,
};

const ViewButton: React.FC<ButtonProps> = ({ ...props }) => {
  if (props.color && props.bgColor && props._hover && props._active) {
    return (
      <IconButton
        aria-label={props.ariaLabel ? props.ariaLabel : props.title}
        bgColor={props.bgColor ? props.bgColor : 'red.300'}
        _hover={props._hover ? props._hover : { bg: 'red.400', color: 'gray.100' }}
        _active={props._active ? props._active : { bg: 'red.500', color: 'gray.200' }}
        _focus={{ outline: 'none' }}
        color={props.color ? props.color : 'white'}
        title={props.title}
        icon={props.icon ? props.icon : <Icon as={MdOutlineRemoveRedEye} />}
        isLoading={props.isLoading ? props.isLoading : undefined}
        isDisabled={props.isDisabled ? props.isDisabled : undefined}
        onClick={props.onClick ? props.onClick : undefined}
      />
    );
  }
  return (
    <IconButton
      aria-label={props.ariaLabel ? props.ariaLabel : props.title}
      colorScheme="gray"
      title={props.title}
      icon={props.icon ? props.icon : <Icon as={MdOutlineRemoveRedEye} />}
      isLoading={props.isLoading ? props.isLoading : undefined}
      isDisabled={props.isDisabled ? props.isDisabled : undefined}
      onClick={props.onClick ? props.onClick : undefined}
    />
  );
};

ViewButton.defaultProps = ButtonDefaultProps;

export default ViewButton;
