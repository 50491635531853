import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import {
  Box, Heading, SimpleGrid, Spacer, Flex, useToast, Spinner
} from '@chakra-ui/react';
import DataTable from 'react-data-table-component';
import { forwardUnauthenticatedUser, deleteRequestHandle } from '../../../utils/helpers';
import Wrapper from '../../../components/wrapper';
import DeleteButton from '../../../components/deleteButton';
import EditButton from '../../../components/editButton';
import VesselService from '../../../services/vessel.service';
import { IVessel } from '../../../interfaces/vessel';
import { deleteSuccessToast, generalErrorToast, deleteFailToast } from '../../../utils/sharedObjects';
import CreateButton from '../../../components/createButton';
import { IRootState } from '../../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../../components/pageWrapper';

const ListVessels = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);
  const [vessels, setVessels] = useState([] as IVessel[]);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadVessels, setReloadVessels] = useState(true);

  useEffect(() => {
    forwardUnauthenticatedUser(user);
    setReloadVessels(false);
    const getVessels = async () => {
      const response = await VesselService.getAll();
      if (response?.data) {
        setVessels(response.data);
      }
      setIsLoading(false);
    };

    if (reloadVessels) {
      getVessels().catch((e) => {
        if (!e.message.includes('404') && !e.message.includes('401')) {
          toast(generalErrorToast());
          console.error(e.message);
        }
      });
    }
  }, [user, toast, reloadVessels]);

  const deleteVessel = async (vesselId : any) => {
    const response = await VesselService.deleteOneById(vesselId);
    const deleteResponse = deleteRequestHandle(response);
    if (deleteResponse) {
      toast(deleteSuccessToast('Vessel'));
      setVessels(vessels.filter((vessel) => vessel.id !== vesselId));
    } else {
      toast(deleteFailToast('Mission'));
    }
  };

  const vesselsButtons = (row: IVessel) => (
    <SimpleGrid columns={2} spacing={5}>
      <Box>
        <DeleteButton
          title="delete vessel"
          onClick={() => deleteVessel(row.id)}
        />
      </Box>
      <Box>
        <EditButton
          title="edit vessel"
          onClick={() => navigate(`/admin/vessels/${row.id}`)}
        />
      </Box>
    </SimpleGrid>
  );
  const vesselsColumns = [
    {
      name: 'Name',
      selector: (row: IVessel) => row.name,
    },
    { name: 'Status', selector: (row: IVessel) => row.status },
    {
      name: 'Action',
      cell: (row: IVessel) => vesselsButtons(row),
    },
  ];

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="md">Vessel management</Heading>
          </Box>
          <Spacer />
          <Box>
            <CreateButton
              title="Add vessel"
              route="/admin/vessels/add"
            />
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="large">
        <DataTable
          columns={vesselsColumns}
          data={vessels}
          pagination
          paginationPerPage={20}
          onRowClicked={(row) => navigate(`/admin/vessels/${row.id}`)}
          progressPending={isLoading}
          progressComponent={<Spinner size="lg" />}
        />
      </Wrapper>
    </PageWrapper>
  );
}

export default ListVessels;
