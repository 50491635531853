import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MdOutlineFormatListBulleted, MdSave } from 'react-icons/md';
import {
  Box, Button, Flex, FormLabel, Heading, Icon, Input, Spacer, useToast,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import CancelButton from '../../../components/cancelButton';
import HarborLocationMap from '../../../components/harborMap';
import InputField from '../../../components/inputField';
import Wrapper from '../../../components/wrapper';
import {
  forwardUnauthenticatedUser, updateRequestHandle, getRequestHandle,
} from '../../../utils/helpers';
import {
  generalErrorToast, updateFailToast, updateSuccessToast,
} from '../../../utils/sharedObjects';
import { ILocation } from '../../../interfaces/location';
import LocationService from '../../../services/location.service';
import { IRootState } from '../../../interfaces/rootState';
import PageWrapper from '../../../components/pageWrapper';

const EditLocation = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { locationId } = useParams();

  const currentUser = useSelector((state: IRootState) => state.user);
  const [location, setLocation] = useState({} as ILocation);
  const [coordinates, setCoordinates] = useState<Array<number>>([]);

  useEffect(() => {
    forwardUnauthenticatedUser(currentUser);
    if (locationId) {
    const getLocation = async () => {
      if (Number.isNaN(Number(locationId))) {
        navigate('/404');
        // this makes sure we run below code only once
      } else {
        const response = await LocationService.getOne(+locationId);
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setLocation(response.data);
          setCoordinates(response.data.coordinates.coordinates);
        } else {
          navigate('/404');
        }
      }
    }
    ;

    getLocation().catch((e) => {
      // only display general error if it was not a 404
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    })
    };
  }, [navigate, currentUser, locationId, toast]);

  const update = async (changedLocation: { location: ILocation }) => {
    const newData = changedLocation.location;
    const response = await LocationService.updateOne(newData.id, newData);
    const updateResponse = updateRequestHandle(response);
    if (updateResponse) {
      toast(updateSuccessToast('Location'));
      navigate('/locations');
    } else {
      toast(updateFailToast('Location'));
    }
  };

  const showOnMap = () => {
    if (typeof document !== 'undefined') {
      const latValue = Number((document.getElementsByName('lat')[0] as HTMLInputElement).value);
      const lngValue = Number((document.getElementsByName('lng')[0] as HTMLInputElement).value);
      location.coordinates = [
          latValue,
          lngValue,
        ];
      setLocation(location);
      setCoordinates([
        latValue,
        lngValue,
      ]);
    }
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Edit Location</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              type="submit"
              colorScheme="teal"
              onClick={() => navigate('/admin/locations')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              List locations
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="medium">
        <Formik
          initialValues={{ location }}
          onSubmit={update}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <InputField
                name="location.id"
                required
                type="hidden"
              />
              <InputField
                label="Name"
                name="location.name"
                required
                type="text"
              />
              <Box marginTop="15px">
                <FormLabel>
                  Latitude
                </FormLabel>
                <Input
                  placeholder="lat"
                  name="lat"
                  required
                  defaultValue={coordinates[0]}
                />
              </Box>
              <Box marginTop="15px">
                <FormLabel>
                  Longitude
                </FormLabel>
                <Input
                  placeholder="lng"
                  name="lng"
                  required
                  defaultValue={coordinates[1]}
                />
                <Button
                  marginTop="15px"
                  onClick={showOnMap}
                >
                  Show on map
                </Button>
              </Box>
              {location && (
                <Box marginTop="15px">
                  <HarborLocationMap
                    locationCoordinates={coordinates}
                    newCoordinates={setCoordinates}
                  />
                </Box>
              )}
              <Button
                marginTop="15px"
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
              >
                <Icon as={MdSave} mb="-2px" mr="5px" />
                Save
              </Button>
              <CancelButton />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PageWrapper>
  );
}

export default EditLocation;
