import { AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch, Action } from "redux";
import { STORE_USER } from '../actions';
import moment from 'moment';
import MapService from '../services/map.service';
import { IUser } from '../interfaces/user';

export function isUserAuthenticated(data : IUser) {
  return data && data.id;
}

export function forwardUnauthenticatedUser(
  user: IUser,
  navigate? : NavigateFunction,
  dispatch? : Dispatch<Action>,
) {
  console.log(isUserAuthenticated(user))
  if (!isUserAuthenticated(user) || dispatch !== undefined) {
    if (dispatch) {
      dispatch({type: STORE_USER, payload: ''})
    }
    if (navigate){
    navigate('/login');
    }
  }
}


// export function setUserCookie(userData, setCookie: (name: 'user', value: any, options?: CookieSetOptions) => void) {
//   setCookie('user', JSON.stringify(userData), {
//     path: '/',
//     maxAge: 3600, // Expires after 1hr
//     sameSite: true,
//   });
// }

// export function removeUserCookie(removeCookie: (name: 'user', options?: CookieSetOptions) => void) {
//   removeCookie('user', { path: '/' });
// }

export async function generatePathFromFile(file : any) {
  return new Promise((resolve) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event : any) => {
      const missionPath = await MapService.displayRoute(event.target.result);
      resolve(missionPath.data);
    };
    fileReader.readAsText(file.target.files[0]);
  });
}

export function rruleToCron(rrule: string, hours: number, minutes: number): string {
  const INCASE_NOT_SUPPORTED = 'NOT-SUPPORTED';

  const r = rrule.replace('RRULE:', '');
  const C_DAYS_OF_WEEK_RRULE = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
  const C_DAYS_WEEKDAYS_RRULE = ['MO', 'TU', 'WE', 'TH', 'FR'];
  const C_DAYS_OF_WEEK_CRONE = ['1', '2', '3', '4', '5', '6', '7'];
  const C_DAYS_OF_WEEK_CRONE_NAMED = [
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
    'SUN',
  ];
  const C_MONTHS = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  const dayTime = `${minutes} ${hours}`;
  let dayOfMonth = '?';
  let month = '*';
  let dayOfWeek = '?';

  let FREQ = '';
  let INTERVAL = -1;
  let BYMONTHDAY = -1;
  let BYMONTH = -1;
  let BYDAY = '';
  let BYSETPOS = 0;

  const rarr = r.split(';');
  rarr.forEach((rarrItem) => {
    const param = rarrItem.split('=')[0];
    const value = rarrItem.split('=')[1];
    if (param === 'FREQ') FREQ = value;
    if (param === 'INTERVAL') INTERVAL = parseInt(value, 10);
    if (param === 'BYMONTHDAY') BYMONTHDAY = parseInt(value, 10);
    if (param === 'BYDAY') BYDAY = value;
    if (param === 'BYSETPOS') BYSETPOS = parseInt(value, 10);
    if (param === 'BYMONTH') BYMONTH = parseInt(value, 10);
  });

  if (FREQ === 'MONTHLY') {
    if (INTERVAL === 1) {
      month = '*'; // every month
    } else {
      month = `1/${INTERVAL}`; // 1 - start of january, every INTERVALth month
    }
    if (BYMONTHDAY !== -1) {
      dayOfMonth = BYMONTHDAY.toString();
    } else if (BYSETPOS !== 0) {
      if (BYDAY === '') {
        console.error('No BYDAY specified for MONTHLY/BYSETPOS rule');
        return INCASE_NOT_SUPPORTED;
      }

      if (BYDAY === 'MO,TU,WE,TH,FR') {
        if (BYSETPOS === 1) {
          // First weekday of every month
          // "FREQ=MONTHLY;INTERVAL=1;BYSETPOS=1;BYDAY=MO,TU,WE,TH,FR",
          dayOfMonth = '1W';
        } else if (BYSETPOS === -1) {
          // Last weekday of every month
          // "FREQ=MONTHLY;INTERVAL=1;BYSETPOS=-1;BYDAY=MO,TU,WE,TH,FR",
          dayOfMonth = 'LW';
        } else {
          console.error(
            'Unsupported Xth weekday for MONTHLY rule (only 1st and last weekday are supported)',
          );
          return INCASE_NOT_SUPPORTED;
        }
      } else if (C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY) === -1) {
        console.error(
          `Unsupported BYDAY rule (multiple days are not supported by crone): ${
            BYDAY}`,
        );
        return INCASE_NOT_SUPPORTED;
      } else {
        dayOfMonth = '?';
        if (BYSETPOS > 0) {
          // 3rd friday = BYSETPOS=3;BYDAY=FR in RRULE, 6#3
          dayOfWeek = `${C_DAYS_OF_WEEK_CRONE[C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY)]
          }#${
            BYSETPOS.toString()}`;
        } else {
          // last specific day
          dayOfWeek = `${C_DAYS_OF_WEEK_CRONE[C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY)]}L`;
        }
      }
    } else {
      console.error('No BYMONTHDAY or BYSETPOS in MONTHLY rrule');
      return INCASE_NOT_SUPPORTED;
    }
  }

  if (FREQ === 'WEEKLY') {
    if (INTERVAL !== 1) {
      console.error('every X week different from 1st is not supported');
      return INCASE_NOT_SUPPORTED;
    }
    if (
      BYDAY.split(',')
        .sort()
        .join(',')
      === C_DAYS_OF_WEEK_RRULE.concat()
        .sort()
        .join(',')
    ) {
      dayOfWeek = '*'; // all days of week
    } else {
      const arrByDayRRule = BYDAY.split(',');
      const arrByDayCron : any[] = [];
      arrByDayRRule.forEach((byDayRRule) => {
        const indexOfDayOfWeek = C_DAYS_OF_WEEK_RRULE.indexOf(byDayRRule);
        arrByDayCron.push(C_DAYS_OF_WEEK_CRONE_NAMED[indexOfDayOfWeek]);
      });
      dayOfWeek = arrByDayCron.join(',');
    }
  }

  if (FREQ === 'DAILY') {
    if (INTERVAL !== 1) {
      dayOfMonth = `1/${INTERVAL.toString()}`;
    }
  }

  if (FREQ === 'YEARLY') {
    if (BYMONTH === -1) {
      console.error('Missing BYMONTH in YEARLY rule');
      return INCASE_NOT_SUPPORTED;
    }
    month = C_MONTHS[BYMONTH - 1];
    if (BYMONTHDAY !== -1) {
      // FREQ=YEARLY;BYMONTH=3;BYMONTHDAY=2  // 2nd day of March
      dayOfMonth = BYMONTHDAY.toString();
    } else if (BYSETPOS === -1) {
      if (
        BYDAY.split(',')
          .sort()
          .join(',')
        === C_DAYS_OF_WEEK_RRULE.concat()
          .sort()
          .join(',')
      ) {
        dayOfMonth = 'L';
      } else if (
        BYDAY.split(',')
          .sort()
          .join(',')
        === C_DAYS_WEEKDAYS_RRULE.concat()
          .sort()
          .join(',')
      ) {
        dayOfMonth = 'LW';
      } else {
        console.error(
          'Last weekends and just last specific days of Month are not supported',
        );
        return INCASE_NOT_SUPPORTED;
      }
    } else if (
      BYDAY.split(',')
        .sort()
        .join(',')
      === C_DAYS_WEEKDAYS_RRULE.concat()
        .sort()
        .join(',')
      && BYSETPOS === 1
    ) {
      dayOfMonth = `${BYSETPOS.toString()}W`;
    } else if (BYDAY.split(',').length === 1) {
      dayOfWeek = `${C_DAYS_OF_WEEK_CRONE[C_DAYS_OF_WEEK_RRULE.indexOf(BYDAY)]
      }#${
        BYSETPOS.toString()}`;
    } else {
      console.error('Multiple days are not supported in YEARLY rule');
      return INCASE_NOT_SUPPORTED;
    }
  }
  return `${dayTime} ${dayOfMonth} ${month} ${dayOfWeek}`;
}

export const getRequestHandle = (response: AxiosResponse) => {
  if (response) {
    return response.status === 200;
  }
  return false;
};

export const updateRequestHandle = (response: AxiosResponse) => {
  if (response) {
    return response.status === 204;
  }
  return false;
};

export const createRequestHandle = (response: AxiosResponse) => {
  if (response) {
    return response.status === 201;
  }
  return false;
};

export const deleteRequestHandle = (response: AxiosResponse) => {
  if (response) {
    return response.status === 204;
  }
  return false;
};

export const calculateMissionProgress = (startDate: string, estimatedLength: number): number => {
  if (!startDate || !estimatedLength) {
    return 0;
  }
  const currentDate = moment(new Date());
  const timeSinceMissionStart = moment.duration(currentDate.diff(startDate));
  const durationMinutes = timeSinceMissionStart.asMinutes();
  return Math.round((durationMinutes / estimatedLength) * 10000) / 100;
};

export const createBatteryCurrentMeasurementPoints = (response: AxiosResponse):
{ current: number | null, seconds: number | null }[] => {
  const battery = response.data?.measurements?.map((measurement : any) => ({
    y: measurement.battery.current / 100,
    x: parseInt(measurement.timestamp, 10),
  }));

  // sort by seconds?
  battery?.sort((a : any, b : any) => a.seconds - b.seconds);

  return battery;
};

export const createMotorPowerConsumptionMeasurementPoints = (response: AxiosResponse):
{ current: number | null, seconds: number | null }[] => {
  const battery = response.data?.measurements?.map((measurement : any) => ({
    y: (measurement.motor.current / 100) * (measurement.motor.voltage / 1000),
    x: parseInt(measurement.timestamp, 10),
  }));

  // sort by seconds?
  battery?.sort((a : any, b : any) => a.seconds - b.seconds);

  return battery;
};

export const createBatteryVoltageMeasurementPoints = (response: AxiosResponse):
{ current: number | null, seconds: number | null }[] => {
  const battery = response.data?.measurements?.filter((measurement : any) => {
    if (measurement.timestamp === null || measurement.timestamp === 0) {
      console.log("heihoo")
      return false;
    }
    return true;
  }).map((measurement : any) => ({
    y: measurement.battery.voltage / 1000,
    x: parseInt(measurement.timestamp, 10),
  }));

  // sort by seconds?
  battery?.sort((a : any, b : any) => a.seconds - b.seconds);

  return battery;
};

export const createWindSpeedMeasurementPoints = (response: AxiosResponse):
{ depth: number | null, seconds: number | null }[] => {
  const depth = response.data?.measurements?.filter((measurement : any) => {
    if (measurement.timestamp === null || measurement.timestamp === 0) {
      console.log("heihoo")
      return false;
    }
    return true;
  }).map((measurement : any) => ({
    y: measurement.windSpeed === null ? 0 : measurement.windSpeed / 100,
    x: parseInt(measurement.timestamp, 10),
  }));

  // sort by seconds?
  depth?.sort((a : any, b : any) => a.seconds - b.seconds);

  return depth;
};

export const createSpeedMeasurementPoints = (response: AxiosResponse):
{ depth: number | null, seconds: number | null }[] => {
  const depth = response.data?.measurements?.map((measurement : any) => ({
    y: measurement.sog / 1000,
    x: parseInt(measurement.timestamp, 10),
  }));

  // sort by seconds?
  depth?.sort((a : any, b : any) => a.seconds - b.seconds);

  return depth;
};

export const createFuelMeasurementPoints = (response: AxiosResponse):
{ fuel: number | null, seconds: number | null }[] => {
  const fuel = response.data?.measurements?.fuel?.map((measurement : any) => ({
    y: measurement.fuelLevel,
    x: measurement.timestamp,
  }));

  // sort by seconds?
  fuel?.sort((a : any, b : any) => a.seconds - b.seconds);

  return fuel;
};

// type State = {
//   vessels?: IVessel[];
//   selectedVessel?: IVessel | null;
//   selectedMission?: IMission | null;
// };

// export const setState = (newState: State): State => {
//   if (typeof window === 'undefined') {
//     return {} as State;
//   }
//   const oldState = JSON.parse(window?.localStorage?.getItem('state')) || {} as State;
//   const state = { ...oldState, ...newState };
//   window?.localStorage?.setItem('state', JSON.stringify(state));
//   return state;
// };

// export const getState = (): State => {
//   if (typeof window === 'undefined') {
//     return {} as State;
//   }
//   return JSON.parse(window?.localStorage?.getItem('state')) || {} as State;
// };
