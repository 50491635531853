import React, { ReactElement } from 'react';
import {
  AlertDialog, AlertDialogOverlay, CSSObject, Icon, IconButton,
  AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, Button,
} from '@chakra-ui/react';
import { MdDeleteForever } from 'react-icons/md';

type ButtonProps = {
  title: string;
  ariaLabel?: string;
  color?: string;
  bgColor?: string;
  _hover?: CSSObject;
  _active?: CSSObject;
  icon?: ReactElement;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
};

const ButtonDefaultProps = {
  ariaLabel: '',
  color: '',
  bgColor: '',
  _hover: {},
  _active: {},
  icon: undefined,
  isLoading: false,
  isDisabled: false,
  onClick: undefined,
  buttonText: undefined,
};

const DeleteButton: React.FC<ButtonProps> = ({ ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<HTMLButtonElement |  null>(null);

  const alertDialog = () => (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete confirmation
        </AlertDialogHeader>

        <AlertDialogBody>
          Are you sure?
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="red"
            onClick={props.onClick ? props.onClick : undefined}
            ml={3}
          >
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );

  if (props.buttonText) {
    return (
      <>
        {alertDialog()}
        <Button
          title={props.title ? props.title : 'Delete'}
          isDisabled={props.isDisabled ? props.isDisabled : undefined}
          onClick={() => setIsOpen(true)}
          colorScheme="red"
        >
          {props.buttonText}
        </Button>
      </>
    );
  }

  return (
    <>
      {alertDialog()}
      <IconButton
        aria-label={props.ariaLabel ? props.ariaLabel : props.title}
        colorScheme="red"
        title={props.title}
        icon={props.icon ? props.icon : <Icon as={MdDeleteForever} />}
        isLoading={props.isLoading ? props.isLoading : undefined}
        isDisabled={props.isDisabled ? props.isDisabled : undefined}
        onClick={() => setIsOpen(true)}
      />
    </>
  );
};

DeleteButton.defaultProps = ButtonDefaultProps;

export default DeleteButton;
