import api from '../utils/api';
import CrudService from './crud.service';

class LocationService extends CrudService {
  constructor() {
    super('location');
  }

  getAllLocations() {
    return api.get('/location/');
  }

  getOne(locationId : number) {
    return api.get(`${this.base}/${locationId}`);
  }
}

export default new LocationService();
