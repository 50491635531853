import { Routes, Route } from 'react-router-dom';
import Home from './pages';
import ListLocations from './pages/admin/locations';
import AddLocation from './pages/admin/locations/add';
import EditLocation from './pages/admin/locations/edit';
import ListUsers from './pages/admin/users';
import AddUser from './pages/admin/users/add';
import EditUser from './pages/admin/users/edit';
import ListVessels from './pages/admin/vessels';
import AddVessel from './pages/admin/vessels/add';
import EditVessel from './pages/admin/vessels/edit';
import Login from './pages/auth/login';
import Logout from './pages/auth/logout';
import ListMissions from './pages/missions';
import AddMission from './pages/missions/add';
import DetailsMission from './pages/missions/details';
import UserSettings from './pages/user';
import DetailsVessel from './pages/vessels/details';


const Main = () => {
    return (
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/logout' element={<Logout/>}></Route>
        <Route path='/user' element={<UserSettings/>}></Route>
        <Route path='/missions/:missionId/details' element={<DetailsMission/>}></Route>
        <Route path='/missions' element={<ListMissions/>}></Route>
        <Route path='/missions/add' element={<AddMission/>}></Route>
        <Route path='/vessels/:vesselId' element={<DetailsVessel/>}></Route>
        <Route path='/admin/users' element={<ListUsers/>}></Route>
        <Route path='/admin/users/:userId' element={<EditUser/>}></Route>
        <Route path='/admin/users/add' element={<AddUser/>}></Route>
        <Route path='/admin/vessels' element={<ListVessels/>}></Route>
        <Route path='/admin/vessels/:vesselId' element={<EditVessel/>}></Route>
        <Route path='/admin/vessels/add' element={<AddVessel/>}></Route>
        <Route path='/admin/locations' element={<ListLocations/>}></Route>
        <Route path='/admin/locations/add' element={<AddLocation/>}></Route>
        <Route path='/admin/locations/:locationId' element={<EditLocation/>}></Route>
      </Routes>
    );
  }
  
  export default Main;