export const STORE_VESSELS = "STORE_VESSELS"
export const STORE_VESSEL = "STORE_VESSEL"
export const STORE_MISSION = "STORE_MISSION"
export const STORE_USER = "STORE_USER"

export function storeVessels(payload){
    return {
        type: STORE_VESSELS, payload
    }
}

export function storeVessel(payload){
    return {
        type: STORE_VESSEL, payload
    }
}

export function storeMission(payload){
    return {
        type: STORE_MISSION, payload
    }
}

export function storeUser(payload){
    return {
        type: STORE_USER, payload
    }
}