import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { MdOutlineFormatListBulleted, MdSave } from 'react-icons/md';
import {
  Box, Button, Flex, FormLabel, Heading, Icon, Spacer, Switch, useToast, Text,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";

import Select from 'react-select';
import CancelButton from '../../../components/cancelButton';
import InputField from '../../../components/inputField';
import Wrapper from '../../../components/wrapper';
import { forwardUnauthenticatedUser, createRequestHandle, getRequestHandle } from '../../../utils/helpers';
import UserService from '../../../services/user.service';
import VesselService from '../../../services/vessel.service';
import { IUser } from '../../../interfaces/user';
import {
  createSuccessToast, createFailToast, generalErrorToast,
} from '../../../utils/sharedObjects';
import { initialUser } from '../../../utils/initialValues';
import { IVessel } from '../../../interfaces/vessel';
import { IRootState } from '../../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../../components/pageWrapper';

const AddUser = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const currentUser = useSelector((state: IRootState) => state.user);
  const [toggle, setToggle] = useState(true);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [userRoleId, setUserRoleId] = useState(0);
  const [availableVessels, setAvailableVessels] = useState([]);
  const [assignedVessels, setAssignedVessels] = useState([]);

  const { userId } = useParams();

  useEffect(() => {
    forwardUnauthenticatedUser(currentUser);
    


    const getAvailableUsers = async () => {
        const response = await UserService.getAllUserRoles();
        if (response.status === 200) {
          setAvailableUsers(response.data.map((userItem : any) => ({
            value: userItem.id.toString(10),
            label: `${userItem.role}`,
          })));
        } else if (response.status === 404) {
          navigate('/404');
        }
    };

    const getAvailableVessels = async () => {
        const response = await VesselService.getAll();
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setAvailableVessels(response.data.map((availableVessel: IVessel) => ({
            value: availableVessel.id.toString(10), label: `${availableVessel.name}`,
          })));
        } else {
          navigate('/404');
        }
      
    };

    const getAssignedVessels = async () => {
      if (Number.isNaN(Number(userId))) {
        navigate('/404');
        // this makes sure we run below code only once
      } else if (userId !== undefined) {
        const response = await UserService.getUserVessels(+userId);
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setAssignedVessels(response.data);
        } else {
          navigate('/404');
        }
      }
    };

    getAvailableUsers().catch((e) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });

    getAvailableVessels().catch((e: Error) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });

    getAssignedVessels().catch((e: Error) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });
  }, [navigate, userId, currentUser, toast]);

  const user = initialUser();

  const getVesselsSelectDefaultValue = () => availableVessels
    .filter(({ value }) => assignedVessels.some(({ vesselId }) => value === vesselId));

  const onVesselSelectChange = async (selectedValues : any) => {
    const newAssignedVessels = selectedValues.map(( value: string | number ) => ({ vesselId: +value }));
    setAssignedVessels(newAssignedVessels);
  };

  const save = async (formData: { user: IUser }) => {
    const newUser = formData.user;
    newUser.isActive = toggle;
    newUser.userRoleId = userRoleId;
    newUser.vesselIds = assignedVessels;
    const response = await UserService.addOne(newUser);
    const createResponse = createRequestHandle(response);
    if (createResponse) {
      toast(createSuccessToast('User'));
      navigate('/admin/users');
    } else {
      toast(createFailToast('User'));
    }
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">Add User</Heading>
          </Box>
          <Spacer />
          <Box>
            <Button
              type="submit"
              colorScheme="teal"
              onClick={async () => navigate('/admin/users')}
            >
              <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
              List users
            </Button>
          </Box>
        </Flex>
      </Wrapper>
      <Wrapper variant="small">
        <Formik initialValues={{ user }} onSubmit={save}>
          {({ isSubmitting }) => (
            <Form>
              <InputField
                name="user.id"
                required
                type="hidden"
                autoComplete="off"
              />
              <InputField
                label="Firstname"
                name="user.firstName"
                required
                type="text"
              />
              <Box marginTop="15px">
                <InputField
                  label="Surname"
                  name="user.surname"
                  type="text"
                  required
                />
              </Box>
              <Box marginTop="15px">
                <InputField
                  label="Email"
                  name="user.email"
                  type="email"
                  required
                  autoComplete="new-email"
                />
              </Box>
              <Box marginTop="15px">
                <InputField
                  label="Password"
                  name="user.password"
                  type="password"
                  required
                  autoComplete="new-password"
                />
              </Box>
              <Box marginTop="15px">
                <Text>
                  User Role
                </Text>
                <Select
                  options={availableUsers}
                  menuPlacement="auto"
                  // @ts-ignore
                  onChange={({ value }) => setUserRoleId(value)}
                />
              </Box>
              <Box marginTop="15px">
                <FormLabel htmlFor="user.isActive">Active?</FormLabel>
                <Switch
                  id="user.isActive"
                  name="user.isActive"
                  colorScheme="teal"
                  isChecked={toggle}
                  onChange={handleToggle}
                />
              </Box>
              <Text pb="10px">
                Select vessel(s) for this user
              </Text>
              <Select
                options={availableVessels}
                menuPlacement="auto"
                defaultValue={getVesselsSelectDefaultValue}
                isMulti
                onChange={onVesselSelectChange}
                key={getVesselsSelectDefaultValue().toString()}
              />
              <Button
                marginTop="15px"
                type="submit"
                colorScheme="teal"
                isLoading={isSubmitting}
              >
                <Icon as={MdSave} mb="-2px" mr="5px" />
                Save
              </Button>
              <CancelButton />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </PageWrapper>
  );
}

export default AddUser;
