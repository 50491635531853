import React, { useEffect, useState } from 'react';
import {
  Box, Divider, Flex, Code, Button, Icon,
  Heading, ListItem, SimpleGrid, Spacer, Spinner, Table, TableCaption,
  Tbody, Td, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Th, Thead, Tr, UnorderedList, useToast,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import { MdOutlineFormatListBulleted } from 'react-icons/md';
import Map from '../../../components/map';
import moment from 'moment';
import Select from 'react-select';
import Wrapper from '../../../components/wrapper';
import {
  forwardUnauthenticatedUser,
  getRequestHandle,
  createRequestHandle, deleteRequestHandle, updateRequestHandle,
} from '../../../utils/helpers';
import {
  deleteFailToast,
  deleteSuccessToast,
  generalErrorToast, updateFailToast, updateSuccessToast,
} from '../../../utils/sharedObjects';
import VesselService from '../../../services/vessel.service';
import { IVehicleSubsystem } from '../../../interfaces/vehicleSubsystem';
import UserService from '../../../services/user.service';
import { IUser } from '../../../interfaces/user';
import { initialVessel } from '../../../utils/initialValues';
import DeleteButton from '../../../components/deleteButton';
//import StatusCircle from '../../../components/statusCircle';
import { IStatusLogEntry } from '../../../interfaces/statusLogEntry';
import SubsystemService from '../../../services/subsystem.service';
import { ISubsystemType } from '../../../interfaces/subsystemType';
import { IRootState } from '../../../interfaces/rootState';
import PageWrapper from '../../../components/pageWrapper';

const EditVessel = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);
  const [vessel, setVessel] = useState(initialVessel);
  const [reloadVessel, setReloadVessel] = useState(true);
  const [vesselSubsystemStatus, setVesselSubsystemStatus] = useState([]);
  const [availableSubsystems, setAvailableSubsystems] = useState([]);
  const [statusLog, setStatusLog] = useState([] as IStatusLogEntry[]);
  const [openConfiguration, setOpenConfiguration] = useState(false);
  const [openVesselUsers, setOpenVesselUsers] = useState(false);
  const [openVesselSubsystemStatus, setOpenVesselSubsystemStatus] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const { vesselId } = useParams();

  useEffect(() => {
    forwardUnauthenticatedUser(user, navigate);
    if (Number(vesselId) !== vessel?.id) {
      setReloadVessel(true);
    }

    const getVessel = async () => {
      if (Number.isNaN(Number(vesselId))) {
        navigate('/404');
      } else if (reloadVessel && vesselId) {
        const response = await VesselService.getOneById(+vesselId);
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setVessel(response.data);
          setReloadVessel(false);
          setIsLoading(false);
        } else {
          navigate('/404');
        }
      }
    };
    getVessel().catch((e: Error) => {
      if (!e.message.includes('404') && !e.message.includes('401')) {
        toast(generalErrorToast());
        console.error(e.message);
      }
    });
  }, [navigate, vesselId, user, toast, reloadVessel, vessel]);

  // lazy load and reload vessel users only if vessel users accordion is opened
  useEffect(() => {
    const getAvailableUsers = async () => {
        const response = await UserService.getAll();
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setAvailableUsers(response.data.map((availableUser: IUser) => ({
            value: availableUser.id.toString(10), label: `${availableUser.firstName} ${availableUser.surname}`,
          })));
        } else {
          navigate('/404');
        }
    };

    const getAssignedUsers = async () => {
      if (Number.isNaN(Number(vesselId))) {
        navigate('/404');
        // this makes sure we run below code only once
      } else if (vesselId){
        const response = await VesselService.getVesselUsers(+vesselId);
        const getResponse = getRequestHandle(response);
        if (getResponse) {
          setAssignedUsers(response.data);
        } else {
          navigate('/404');
        }
      }
    };

    if (openVesselUsers) {
      getAvailableUsers().catch((e: Error) => {
        if (!e.message.includes('404') && !e.message.includes('401')) {
          toast(generalErrorToast());
          console.error(e.message);
        }
      });
      getAssignedUsers().catch((e: Error) => {
        if (!e.message.includes('404') && !e.message.includes('401')) {
          toast(generalErrorToast());
          console.error(e.message);
        }
      });
    }
  }, [navigate, vesselId, user, toast, openVesselUsers]);

  // lazy load and reload vessel subsystem status only if vessel subsystem accordion is opened
  useEffect(() => {
    const getVesselSubsystemStatus = async () => {
      const response = await SubsystemService.getVesselSubsystemStatus(vessel.id);
      const getResponse = getRequestHandle(response);
      if (getResponse) {
        setVesselSubsystemStatus(response.data);
      } else {
        navigate('/404');
      }
    };

    const getAvailableSubsystems = async () => {
      const response = await SubsystemService.getAllAvailable();
      const getResponse = getRequestHandle(response);
      if (getResponse) {
        setAvailableSubsystems(response.data.map((subsystem: ISubsystemType) => ({
          value: subsystem.id.toString(10), label: subsystem.description,
        })));
      } else {
        navigate('/404');
      }
    };

    if (openVesselSubsystemStatus) {
      getVesselSubsystemStatus().catch((e: Error) => {
        if (!e.message.includes('404') && !e.message.includes('401')) {
          toast(generalErrorToast());
          console.error(e.message);
        }
      });

      if (user?.role === 'admin') {
        getAvailableSubsystems().catch((e: Error) => {
          if (!e.message.includes('404') && !e.message.includes('401')) {
            toast(generalErrorToast());
            console.error(e.message);
          }
        });
      }
    }
  }, [navigate, toast, openVesselSubsystemStatus, vessel.id, user?.role]);

  const getOwnersSelectDefaultValue = () => availableUsers
    .filter(({ value }) => assignedUsers.some(({ userId }: any) => value === userId.toString()));

  const onTabChange = ((selectedTab: number) => {
    // index 3 is accordion item with "Status Log"
    setReloadVessel(selectedTab === 3);
    // index 2 is accordion item with "Vessel Users"
    setOpenVesselUsers(selectedTab === 2);
    // index 1 is accordion item with "Vessel Subsystem Status"
    setOpenVesselSubsystemStatus(selectedTab === 1);
  });

  const onOwnerSelectChange = async (selectedValues: any) => {
    const newAssignedUsers = selectedValues.map(({ value } : any) => ({ userId: +value }));
    setAssignedUsers(newAssignedUsers);
    const response = await VesselService.updateVesselUsers(vessel.id, newAssignedUsers);
    const createResponse = createRequestHandle(response);
    if (createResponse) {
      toast(updateSuccessToast(`Users of vessel ${vessel.name}`));
    } else {
      toast(updateFailToast(`Users of vessel ${vessel.name}`));
    }
  };

  const onSubsystemsSelectChange = async ({ value, label } : any) => {
    const response = await SubsystemService.addToVehicle(+value, vessel.id);
    const updateResponse = updateRequestHandle(response);
    if (updateResponse) {
      setReloadVessel(true);
      toast(updateSuccessToast(`Vessel ${vessel.name} with subsystem ${label}`));
    } else {
      toast(updateFailToast(`Vessel ${vessel.name} with subsystem ${label}`));
    }
  };

  const removeSubsystem = async (subsystemId: number) => {
    const response = await SubsystemService.removeSubsystem(subsystemId);
    const deleteResponse = deleteRequestHandle(response);
    if (deleteResponse) {
      toast(deleteSuccessToast('Vessel Subsystem'));
      vessel.vehicleSubsystems = vessel.vehicleSubsystems.filter(({ id }) => id !== subsystemId);
      setVessel({ ...vessel });
    } else {
      toast(deleteFailToast('Vessel Subsystem'));
    }
  };

  const deleteVessel = async (vesselId: number) => {
    setIsDeleteLoading(true);
    const response = await VesselService.deleteOneById(vesselId);
    const deleteResponse = deleteRequestHandle(response);
    if (deleteResponse) {
      toast(deleteSuccessToast('Vessel'));
      navigate('/');
    } else {
      toast(deleteFailToast('User'));
    }
    setIsDeleteLoading(false);
  };


  return (
    <PageWrapper>
      <Wrapper variant="large">
        <Flex>
          <Box pl="15px">
            <Heading size="lg">
              {vessel.name}
              {' '}
              management
            </Heading>
          </Box>
          <Spacer />
          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <DeleteButton
                title="Delete vessel"
                isDisabled={isDeleteLoading}
                onClick={() => deleteVessel(vessel.id)}
                buttonText="Delete vessel"
              />
            </Box>
            <Box>
              <Button
                type="submit"
                colorScheme="teal"
                onClick={() => navigate('/admin/vessels')}
              >
                <Icon as={MdOutlineFormatListBulleted} mb="-3px" mr="5px" />
                Vessel List
              </Button>
            </Box>
          </SimpleGrid>

        </Flex>
      </Wrapper>
      <Wrapper variant="large">
        <Box pl="15px">
          <Tabs onChange={(index) => onTabChange(index)}>
            <TabList>
              <Tab>General</Tab>
              <Tab>Subsystems</Tab>
              <Tab>User access</Tab>
              <Tab>Vessel config</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <SimpleGrid columns={2} spacing={10}>
                  <Box marginTop="15px">
                    <UnorderedList>
                      <ListItem>
                        Name:
                        {' '}
                        {vessel.name}
                      </ListItem>
                      <ListItem>
                        Is Active:
                        {' '}
                        {vessel.status ? 'yes' : 'no'}
                      </ListItem>
                      <ListItem>
                        Max Speed:
                        {' '}
                        {vessel.maxSpeed}
                      </ListItem>
                      <ListItem>
                        Draft:
                        {' '}
                        {vessel.draft}
                      </ListItem>
                    </UnorderedList>
                  </Box>
                  <Box marginTop="15px">
                    <UnorderedList>
                      <ListItem>
                        Fuel tank size:
                        {' '}
                        {vessel.fuelTankSize}
                      </ListItem>
                      <ListItem>
                        Battery capacity:
                        {' '}
                        {vessel.batteryCapacity}
                      </ListItem>
                      <ListItem>
                        Created at:
                        {' '}
                        {moment(vessel.createdAt).format('DD.MM.yyyy - HH:mm:ss')}
                      </ListItem>
                      {vessel.vehicleSubsystems?.length
                        ? (
                          <ListItem>
                            Total number of subsystems:
                            {' '}
                            {vessel.vehicleSubsystems.length}
                          </ListItem>
                        ) : null}
                    </UnorderedList>
                  </Box>
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                {vessel.vehicleSubsystems
                  ?.map((subsystem: IVehicleSubsystem, index, { length }) => (
                    <Box key={subsystem.id}>
                      <SimpleGrid columns={3} spacing={10}>
                        <Box marginTop="15px">
                          Name:
                          {' '}
                          {subsystem.subsystemType.description}
                          {subsystem.parameter ? (
                            <Box as="p">
                              Parameter:
                              {' '}
                              {subsystem.parameter}
                            </Box>
                          ) : null}
                        </Box>
                        <Box>
                          {/* <StatusCircle isWorking={
                            vesselSubsystemStatus.find((s : any) => s.id === subsystem.id)
                              ?.working
                          }
                          /> */}
                        </Box>
                        <Box>
                          {user?.role === 'admin' && (
                          <DeleteButton
                            title="remove subsystem"
                            onClick={() => removeSubsystem(subsystem.id)}
                          />
                          )}
                        </Box>
                      </SimpleGrid>
                      {length - 1 === index ? null : <Divider mb="5px" mt="5px" />}
                    </Box>
                  ))}
                <Box mt="35px">
                  {user?.role === 'admin' && availableSubsystems
                    ? (
                      <Select
                        closeMenuOnSelect
                        blurInputOnSelect
                        isSearchable
                        placeholder="Add available subsystem"
                        options={availableSubsystems}
                        menuPlacement="auto"
                        onChange={onSubsystemsSelectChange}
                        key={availableSubsystems.toString()}
                        maxMenuHeight={200}
                      />
                    )
                    : null}
                </Box>
              </TabPanel>
              {user.role === 'admin'
                ? (
                  <TabPanel pb={10}>
                    <Text pb="10px">
                      Here you can assign the vessel to certain users
                    </Text>

                    <Select
                      options={availableUsers}
                      defaultValue={getOwnersSelectDefaultValue}
                      menuPlacement="auto"
                      isMulti
                      onChange={onOwnerSelectChange}
                      key={getOwnersSelectDefaultValue().toString()}
                      maxMenuHeight={100}
                    />

                  </TabPanel>
                )
                : (
                  <TabPanel>
                    <Text pb="10px">
                      Vessel is assigned to users:
                    </Text>
                    {getOwnersSelectDefaultValue().map(({ label }) => label).join(', ')}
                  </TabPanel>
                )}
              <TabPanel>
                <Code w="100%" p="10">
                  <Text>
                    ID =
                    {' '}
                    {vessel.id}
                  </Text>
                  <Text>
                    accessToken =
                    {' '}
                    {vessel.accessToken}
                  </Text>
                  {vessel.vehicleSubsystems
                    ?.map((subsystem: IVehicleSubsystem, index, { length }) => (
                      <Text>
                        {subsystem.subsystemType.vehicleKey}
                        {' '}
                        =
                        {' '}
                        {subsystem.id}
                      </Text>
                    ))}

                </Code>
              </TabPanel>
            </TabPanels>
          </Tabs>

        </Box>
      </Wrapper>
    </PageWrapper>
  );
}

export default EditVessel;
