import { AxiosResponse } from 'axios';
import CrudService from './crud.service';
import api from '../utils/api';

class SubsystemService extends CrudService {
  constructor() {
    super('subsystems');
  }

  getAllAvailable() {
    return api.get(`/${this.base}/available`);
  }

  addToVehicle(subsystemTypeId: number, vehicleId: number) {
    return api.put(`/${this.base}/${subsystemTypeId}/vehicles/${vehicleId}`);
  }

  getVesselSubsystemStatus(
    vesselId: number,
  ): Promise<AxiosResponse> {
    return api.get(`/${this.base}/${vesselId}/subsystem-status`);
  }

  removeSubsystem(
    vehicleSubsystemId: number,
  ): Promise<AxiosResponse> {
    return api.delete(`/${this.base}/${vehicleSubsystemId}`);
  }
}

export default new SubsystemService();
