import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_BACKEND_URL || 'http://localhost:8081',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export default api;
