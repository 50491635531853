import React, { useEffect, useState } from 'react';
import {
  Box, Flex, Heading, Icon, SimpleGrid, Spacer, Spinner,
  Stack, Table, TableCaption, Tbody, Td, Text, Th, Thead, Tr, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { STORE_VESSELS } from '../actions';
import { useTranslation } from "react-i18next";
import { MdFormatListBulleted } from 'react-icons/md';
import { RiLayoutGridLine } from 'react-icons/ri';
import moment from 'moment';

import Wrapper from '../components/wrapper';
import {
  forwardUnauthenticatedUser, getRequestHandle,
} from '../utils/helpers';
import VesselTile from '../components/vesselTile';
import VesselService from '../services/vessel.service';
import { IVessel } from '../interfaces/vessel';
import { generalErrorToast } from '../utils/sharedObjects';
import VesselStatusIcon from '../components/vesselStatusIcon';
import SingleVesselTile from '../components/singleVesselTile';
import { IRootState } from '../interfaces/rootState';
import PageWrapper from '../components/pageWrapper';

const Home = () => {
  let vessels = useSelector((state: IRootState) => state.vessels) ?? [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector((state: IRootState) => state.user);
  const [dashboardVessels, setDashboardVessels] = useState<any[]>(vessels);
  const [tableView, setTableView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation('common')

  useEffect(() => {
    forwardUnauthenticatedUser(user);
    const getVessels = async () => {
      const response = await VesselService.getAll();
      if (getRequestHandle(response)) {
        
        dispatch({ type: STORE_VESSELS, payload: response.data});
        setDashboardVessels(response.data);
        setIsLoading(false);
      }
    };

      getVessels().catch((e: Error) => {
        // prevent error message after user logged out
        if (!e.message.includes('401')) {
          toast(generalErrorToast());
          setIsLoading(false);
        }
      });

  }, [dispatch, toast, user]);

  useEffect(() => {
    if (dashboardVessels?.length > 0) {
      setIsLoading(false);
    }
  }, [dashboardVessels]);

  const handleTableViewSwitch = (enableTableView: boolean) => {
    setTableView(enableTableView);
  };

  let dashboardBody;

  if (dashboardVessels.length === 0) {
    dashboardBody = isLoading
      ? (
        <Wrapper variant="small">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Wrapper>
      )
      : (
        <Wrapper variant="small">
          <Box>
            {t('message.No vessels available')}
          </Box>
        </Wrapper>
      );
  } else if (dashboardVessels.length === 1) {
    const vessel = dashboardVessels[0];

    dashboardBody = (
      <SingleVesselTile
        vessel={vessel}
      />
    );
  } else if (dashboardVessels.length > 1 && tableView) {
    dashboardBody = (
      <Table className="dashboard-table" size="md" mt={5}>
        <TableCaption>Current Vessels</TableCaption>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Location</Th>
            <Th>Connection</Th>
            <Th>
              Last Mission
              <br />
              Start
            </Th>
            <Th>
              Last Mission
              <br />
              End
            </Th>
            <Th>Mission Results</Th>
          </Tr>
        </Thead>
        <Tbody>
          {dashboardVessels !== undefined ? dashboardVessels.map((vessel: IVessel) => {
            const { vehicleStatus } = vessel;
            if (vessel !== undefined && vehicleStatus !== undefined){
            return (
              <Tr key={vessel.id} background={vehicleStatus.isError ? 'rgb(242 71 38)' : undefined}>
                <Td onClick={async () => navigate(`/vessels/${vessel.id}`)} cursor="pointer">{vessel.name}</Td>
                <Td>{vehicleStatus.description}</Td>
                <Td>{vessel.missions !== undefined && vessel.missions[0]?.location !== undefined ? vessel.missions[0]?.location.name : ''}</Td>
                <Td>
                  <VesselStatusIcon
                    isError={vehicleStatus.isError}
                    dataConnection={vehicleStatus.dataConnection}
                  />
                </Td>
                <Td>{vessel.missions !== undefined ? vessel.missions[0]?.startedAt ? moment(vessel.missions[0].startedAt).format('DD/MM/YY HH:mm') : 'no data' : 'no data'}</Td>
                <Td>{vessel.missions !== undefined ? vessel.missions[0]?.endedAt ? moment(vessel.missions[0]?.endedAt).format('DD/MM/YY HH:mm') : 'no data' : 'no data'}</Td>
                <Td>{vehicleStatus.isError ? 'ABNORMALTIES' : 'NORMAL'}</Td>
              </Tr>
            );
            }
            else {
              return (<></>)
            }
          }) : ''}
        </Tbody>
      </Table>
    );
  } else {
    dashboardBody = (
      <SimpleGrid columns={2} spacing={10} mt={5}>
        {dashboardVessels.map((vessel: IVessel, index: number) => (
          <VesselTile key={index.toString()} {...vessel} />
        ))}
      </SimpleGrid>
    );
  }

  return (
    <PageWrapper>
    <Wrapper variant="large">
      <Flex>

        <Spacer />
        <Box>
          <Stack align="end">
            {dashboardVessels.length > 1
              ? (
                <Stack align="center" direction="row" cursor="pointer">
                  <Icon
                    as={RiLayoutGridLine}
                    boxSize={6}
                    color={!tableView ? 'black' : 'gray'}
                    onClick={() => handleTableViewSwitch(false)}
                  />
                  <Icon
                    as={MdFormatListBulleted}
                    boxSize={6}
                    color={tableView ? 'black' : 'gray'}
                    onClick={() => handleTableViewSwitch(true)}
                  />
                </Stack>
              )
              : null}
          </Stack>
        </Box>
      </Flex>
      {dashboardBody}
    </Wrapper>
    </PageWrapper>
  );
}

export default Home;
