import { STORE_VESSELS } from "../actions";
import { STORE_VESSEL } from "../actions";
import { STORE_MISSION } from "../actions";
import { STORE_USER } from "../actions";

const initialState = {
    vessels: '',
    vessel: '',
    mission: '',
    user: ''
}

function rootReducer(state = initialState, action){
    if (action.type === STORE_VESSELS){
        state.vessels = action.payload;
    }
    if (action.type === STORE_VESSEL){
        state.vessel = action.payload;
    }
    if (action.type === STORE_MISSION){
        state.mission = action.payload
    }
    if (action.type === STORE_USER){
        state.user = action.payload;
    }

    return {...state}
}

export default rootReducer;