import React from 'react';
import {
  Button, Icon,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { MdOutlineAddBox } from 'react-icons/md';

type ButtonProps = {
  title: string;
  route: string;
  ariaLabel?: string;
  color?: string;
  bgColor?: string;
  colorScheme?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const ButtonDefaultProps = {
  ariaLabel: '',
  color: '',
  bgColor: '',
  colorScheme: 'teal',
  onClick: undefined,
};

const CreateButton: React.FC<ButtonProps> = ({ ...props }) => {
  const navigate = useNavigate();
  return (
    <Button
      type="submit"
      colorScheme={props.colorScheme}
      onClick={() => navigate(props?.route)}
    >
      <Icon as={MdOutlineAddBox} mr="5px" />
      {props.title}
    </Button>
  );
};

CreateButton.defaultProps = ButtonDefaultProps;

export default CreateButton;
