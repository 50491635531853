import React from 'react';
import { Box } from '@chakra-ui/react';

interface PageWrapperProps {
  children: any,
}

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {

  return (
    <Box marginTop="15px" marginBottom="60px" marginX="auto" width="100%">
      {children}
    </Box>
  );
};

export default PageWrapper;
