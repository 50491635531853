import React from 'react';
import { Icon } from '@chakra-ui/react';
import { MdWifi, MdWifiOff } from 'react-icons/md';

type VesselStatusProps = {
  isError: boolean,
  dataConnection: string,
};

const VesselStatusIcon: React.FC<VesselStatusProps> = ({ isError, dataConnection }) => (
  isError ? (
    <>
      <Icon as={MdWifiOff} mb="-2px" mr="5px" />
      Offline
    </>
  )
    : (
      <>
        <Icon as={MdWifi} mb="-2px" mr="5px" />
        {dataConnection}
      </>
    ));

export default VesselStatusIcon;
