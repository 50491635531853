import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export default function SetMarkerMap({ setMapFocus, newCoordinates } : any) {
  const map = useMap();
  useEffect(() => {
    map.addEventListener({
      click: (e) => {
        setMapFocus([e.latlng.lat, e.latlng.lng]);
        newCoordinates([e.latlng.lat, e.latlng.lng]);
      },
    });
  }, [map, newCoordinates, setMapFocus]);
  return null;
}
