/* eslint-disable spaced-comment */
import React from 'react';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Map from './map';
import moment from 'moment';
import { IVessel } from '../interfaces/vessel';
import VesselStatusIcon from './vesselStatusIcon';
import vesselService from '../services/vessel.service';

function VesselTile({
  name,
  id,
  missions,
  vehicleStatus,
}: IVessel) {
  const navigate = useNavigate();

  const toVesselDetail = async (e: any) => {
    e.stopPropagation();
    navigate(`/vessels/${id}`);
  };

  const handleTileClick = async () => {
    const response = await vesselService.getLatestMissions(+id);
    const vesselMissions = response.data;
    if (vesselMissions) {
      if (vesselMissions?.length > 0) {
        if (vesselMissions[0].active) {
          //await router.push(`/vessels/${id}/singleView`);
          navigate(`/missions/${vesselMissions[0].id}/details`);
        } else {
          navigate(`/missions/${vesselMissions[0].id}/details`);
        }
      } else {
        navigate(`/vessels/${id}`);
      }
    }
  };

  return (
    <Box className="container-tile">
      <Box onClick={(e) => e.stopPropagation()}>
        <Map
          zoom={14}
          initialCameraView={false}
          depthThreshold={10}
          vesselId={id}
          style={{
            height: '25vh',
            width: '100%',
            borderRadius: '0.5rem',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          enableLayers={false}
          initialCoordinates={
            (missions !== undefined && missions[0]?.location)
              ? [Number((missions[0].location !== undefined && missions[0].location.coordinates !== undefined) ? missions[0].location.coordinates[0]: 0),
                Number((missions[0].location !== undefined && missions[0].location.coordinates !== undefined) ? missions[0].location.coordinates[1]: 0)]
              : undefined
          }
        />
      </Box>
      <Box
        className="container__profile__text"
        bgColor={vehicleStatus !== undefined ? vehicleStatus.isError ? 'rgb(242 71 38)': undefined : undefined}
        onClick={handleTileClick}
        cursor="pointer"
      >
        {missions !== undefined && missions[0] ? (
          <Box>
            <Box as="p">
              <b>Last mission:</b>
              {' '}
              {missions[0].name}
            </Box>
            <Box as="p">
              <b>Started at:</b>
              {' '}
              {missions[0].startedAt
                ? moment(missions[0].startedAt).format('DD/MM/YY HH:mm')
                : 'no data'}
            </Box>
            <Box as="p">
              <b>Ended at:</b>
              {' '}
              {missions[0].endedAt
                ? moment(missions[0].endedAt).format('DD/MM/YY HH:mm')
                : 'no data'}
            </Box>
          </Box>
        ) : (
          <Box />
        )}
      </Box>
      <Box
        className="container__vessel__data"
        bgColor={vehicleStatus !== undefined ? vehicleStatus.isError ? 'rgb(242 71 38)' : 'gray.100' : 'gray.100'}
        onClick={handleTileClick}
        cursor="pointer"
      >
        <Flex>
          <Box onClick={toVesselDetail}><b>{name}</b></Box>
          <Spacer />
          <Box>
            {missions !== undefined ? missions[0]?.location?.name ?? '' : ''}
          </Box>
          <Spacer />
          <Box>{vehicleStatus?.description}</Box>
          <VesselStatusIcon
            isError={vehicleStatus !== undefined ? vehicleStatus.isError : false}
            dataConnection={vehicleStatus !== undefined ? vehicleStatus.dataConnection : ''}
          />
        </Flex>
      </Box>
    </Box>
  );
}

export default VesselTile;
