import React from 'react';
import { Box, Progress } from '@chakra-ui/react';
import { VideoJS } from './videoJs';

type VesselMapOverlayProps = {
  progressBar: number,
  propProgressBar: number,
  pssbProgressBar: number,
  sog?: number,
  eta: string,
  powerConsumption: string,
  remainingDistance: string,
  enableCameraView: boolean
};

const videoJsOptions = {
  autoplay: true,
  controls: true,
  responsive: true,
  fluid: true,
  sources: [{
    src: '/stream/master.m3u8',
    type: 'application/x-mpegurl',
  }],
};

const VesselMapOverlay: React.FC<VesselMapOverlayProps> = (
  {
    progressBar,
    propProgressBar,
    pssbProgressBar,
    sog,
    eta,
    powerConsumption,
    remainingDistance,
    enableCameraView,
  },
) => (
  <Box position="absolute" w="350px" h="80vh" className="mapOverlay">
    {/* <Box position="relative" top="35px" left="50px" zIndex={500} w="700px" title={progressBar.toString(10)}>
      <Progress colorScheme="green" size="sm" value={progressBar} border="1px" />
    </Box>
    <Box position="relative" top="65px" left="50px" zIndex={500} fontWeight="bold">
      SOG:
      {' '}
      {sog}
      <br />
      ETA:
      {' '}
      {eta}
      <br />
      {powerConsumption}
      <br />
      <Box as="p" fontWeight="normal">
        {remainingDistance}
      </Box>
    </Box>
    <Box
      position="relative"
      top={mapHeight - 515}
      left="-45px"
      zIndex={500}
      w="200px"
      transform="rotate(-90deg)"
    >
      <Progress colorScheme="green" size="lg" value={propProgressBar} border="1px" />
    </Box>
    <Box
      position="relative"
      top={mapHeight - 530}
      left="-20px"
      zIndex={500}
      w="200px"
      transform="rotate(-90deg)"
      title={pssbProgressBar.toString(10)}
    >
      <Progress colorScheme="green" size="lg" value={pssbProgressBar} border="1px" />
    </Box>
    <Box
      position="relative"
      top="40px"
      left="45px"
      zIndex={500}
      display="unset"
    >
      <Box as="b">
        Prop
        <br />
        PS SB
      </Box>
    </Box>
    <Box
      fontSize="sm"
      position="relative"
      top={mapHeight - 672}
      left="-40px"
      zIndex={500}
      display="unset"
    >
      100%
    </Box>
    <Box
      fontSize="sm"
      position="relative"
      top={mapHeight - 572}
      left="-70px"
      zIndex={500}
      display="unset"
    >
      0%
    </Box>
    <Box
      fontSize="sm"
      position="relative"
      top={mapHeight - 475}
      left="-95px"
      zIndex={500}
      display="unset"
    >
      100%
    </Box> */}
    {/* <Box
      position="absolute"
      zIndex={500}
      width="30vh"
      className="videoContainer"
      bottom={0}
    >
      {enableCameraView
        ? (
          <VideoJS className="live-video" options={videoJsOptions} />
        )
        : null}
    </Box> */}
  </Box>
);

export default VesselMapOverlay;
