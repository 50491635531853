import React from 'react';
import {
  Box, Link, Container, Stack, Text, useColorModeValue,
} from '@chakra-ui/react';

const Footer: React.FC = () => (
  <Box
    position="absolute"
    bottom="0"
    width="100%"
    h={50}
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}
    zIndex="1000" // zoom buttons from leaflet map have zIndex of 999
  >
    <Container
      as={Stack}
      py={4}
      direction={{ base: 'column', md: 'row' }}
      spacing={4}
      justify={{ base: 'center', md: 'space-between' }}
    >
      <Text fontSize="sm">&copy; MINDCHIP OÜ, 2022</Text>
      <Stack direction="row" spacing="10">
        <Link href="#">Contact</Link>
        <Link href="#">FAQ</Link>
        <Link href="#">T&C</Link>
      </Stack>
    </Container>
  </Box>
);

export default Footer;
