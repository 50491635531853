import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import {
  Box, Button, Heading, Icon, Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { STORE_USER } from '../../actions';
import { MdAlternateEmail, MdLogin, MdPassword } from 'react-icons/md';
import Wrapper from '../../components/wrapper';
import InputField from '../../components/inputField';
import { isUserAuthenticated } from '../../utils/helpers';
import AuthService from '../../services/auth.service';
import { IRootState } from '../../interfaces/rootState';
import React from 'react';
import PageWrapper from '../../components/pageWrapper';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.user);

  useEffect(() => {
    if (isUserAuthenticated(user)) {
      navigate('/');
    }
  }, [navigate, user]);

  const setLoginErrors = (setErrors : any) => {
    setErrors({
      email: 'Email may be incorrect.',
      password: 'Password may be incorrect.',
    });
  };

  const login = async (values : any, { setErrors } : any) => {
    try {
      console.log(values)
      const response = await AuthService.login(values);
      if (response.status === 200 && response.data?.user) {
        dispatch({type: STORE_USER, payload: response.data.user})
        navigate('/');
      } else {
        setLoginErrors(setErrors);
      }
    } catch (error) {
      setLoginErrors(setErrors);
    }
  };

  return (

    <PageWrapper>
    <Wrapper variant="small">
      <Box mt="30px" mb="30px">
        <Heading size="lg">Login</Heading>
      </Box>
      <Formik initialValues={{ email: '', password: '' }} onSubmit={login}>
        {({ isSubmitting }) => (
          <Form>
            <InputField
              label="Email"
              name="email"
              required
              type="email"
            />
            <Box marginTop="15px">
              <InputField
                label="Password"
                name="password"
                type="password"
                required
              />
            </Box>
            <Button
              marginTop="15px"
              type="submit"
              colorScheme="teal"
              leftIcon={<Icon as={MdLogin} mb="-2px" />}
              isLoading={isSubmitting}
            >
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
    </PageWrapper>
  );
}

export default Login;
