import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export default function ChangeMapView({ coords } : any) {
  const map = useMap();
  useEffect(() => {
    if (coords?.length > 0) {
      map.setView(coords, map.getZoom());
    }
  }, [coords, map]);
  return null;
}
