import React, { InputHTMLAttributes, ReactElement } from 'react';
import {
  FormControl, FormErrorMessage, FormLabel, Input,
} from '@chakra-ui/react';
import { useField } from 'formik';

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  placeholder?: string;
  icon?: ReactElement;
};

const InputFieldDefaultProps = {
  label: '',
  placeholder: undefined,
  icon: undefined,
};

const InputField: React.FC<InputFieldProps> = ({ size, ...props }) => {
  const [field, { error }] = useField(props);

  let placeholder = props.placeholder ? props.placeholder : null;
  //placeholder = placeholder || props.label;
  placeholder = placeholder || `Please enter a value for ${props.name}`;

  return (
    <FormControl isInvalid={!!error}>
      {props.label ? (
        <FormLabel htmlFor={field.name}>
          {props.icon}
          {props.label}
        </FormLabel>
      ) : null}
      <Input
        {...field}
        {...props}
        id={field.name}
        placeholder={placeholder}
      />
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  );
};

InputField.defaultProps = InputFieldDefaultProps;

export default InputField;
